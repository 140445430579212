$multi-step-icon-bg: $gray-200 !default;
$multi-step-icon-color: $gray-600 !default;
$multi-step-icon-border-radius: 100px !default;
$multi-step-icon-font-size: 0.875rem !default;
$multi-step-icon-font-weight: $font-weight-semi-bold !default;

$multi-step-icon-hover-color: $multi-step-icon-color !default;

$multi-step-indicator-label-color: $gray-600 !default;
$multi-step-indicator-label-font-size: 0.875rem !default;
$multi-step-indicator-label-font-weight: $font-weight-semi-bold !default;

$multi-step-title-color: $gray-600 !default;
$multi-step-title-font-size: 0.875rem !default;
$multi-step-title-font-weight: $font-weight-semi-bold !default;

$multi-step-icon-disabled-bg: $multi-step-icon-bg !default;
$multi-step-icon-disabled-color: $gray-500 !default;