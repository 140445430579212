$popover-inline-scroller-max-height: 14.75rem !default; // 236px

$popover-arrow-offset: 0.375rem !default; // 6px

$popover-bottom-arrow-color: $popover-header-bg !default;
$popover-bottom-left-arrow-color: $popover-header-bg !default;
$popover-bottom-right-arrow-color: $popover-header-bg !default;
$popover-left-arrow-color: null !default;
$popover-left-bottom-arrow-color: null !default;
$popover-left-top-arrow-color: $popover-header-bg !default;
$popover-right-arrow-color: null !default;
$popover-right-bottom-arrow-color: null !default;
$popover-right-top-arrow-color: $popover-header-bg !default;
$popover-top-arrow-color: null !default;
$popover-top-left-arrow-color: null !default;
$popover-top-right-arrow-color: null !default;

$popover-header-border-color: null !default;
$popover-header-font-size: $font-size-base !default;
$popover-header-font-weight: $font-weight-semi-bold !default;
$popover-header-margin-x: null !default;
$popover-header-margin-y: null !default;

$popover-offset-border-width: calc(#{$popover-border-radius} - #{$popover-border-width}) !default;