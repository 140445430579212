$clay-range-disabled-color: $gray-600 !default;

$clay-range-input-group: () !default;
$clay-range-input-group: map-deep-merge((
	align-items: center,
), $clay-range-input-group);

$clay-range-input-group-item: () !default;
$clay-range-input-group-item: map-deep-merge((
	flex-direction: column,
), $clay-range-input-group-item);

$clay-range-input-group-text: () !default;
$clay-range-input-group-text: map-deep-merge((
	bg: transparent,
	border-width: 0,
	color: $body-color,
	font-size: 0.875rem,
	font-weight: $font-weight-semi-bold,
	min-width: 1.5rem,
	padding-bottom: 0,
	padding-left: 0,
	padding-right: 0,
	padding-top: 0,
), $clay-range-input-group-text);

$clay-range-title: () !default;
$clay-range-title: map-deep-merge((
	display: block,
	font-weight: $font-weight-semi-bold,
	line-height: 1,
	margin-bottom: 0.5rem,
	text-align: center,
), $clay-range-title);

// `data-label-before-content` and `data-label-after-content`, see https://issues.liferay.com/browse/LPS-147457 and https://github.com/sass/sass/issues/1395

$clay-range-input: () !default;
$clay-range-input: map-deep-merge((
	display: block,
	padding-bottom: 0.1px,
	position: relative,
	vertical-align: middle,
	width: 100%,
	thumb-appearance: none,
	thumb-bg: $white,
	thumb-border-radius: 100px,
	thumb-border-width: 0,
	thumb-box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3),
	thumb-height: 1.5rem,
	thumb-width: 1.5rem,
	track-appearance: none,
	track-bg: $gray-300,
	track-border-radius: 100px,
	track-height: 0.25rem,
	track-position: absolute,
	track-top: 50%,
	track-width: 100%,
	progress-bg: $primary,
	progress-border-radius: 100px 0 0 100px,
	progress-position: absolute,
	progress-top: 50%,
	progress-width: 50%,
	tooltip-padding: 0.25rem 0.5rem,
	tooltip-spacer-y: 0.25rem,
	tooltip-transition: opacity 0.15s linear,
	tooltip-visibility: hidden,
	tooltip-white-space: nowrap,
	tooltip-arrow-size: 0.375rem,
	form-control-appearance: none,
	form-control-bg: transparent,
	form-control-height: $input-height,
	form-control-position: relative,
	form-control-z-index: $zindex-clay-range-input-form-control,
	data-label-font-size: 0.875rem,
	data-label-font-weight: $font-weight-semi-bold,
	data-label-line-height: 1,
	data-label-margin-top: 2.5rem,
	data-label-position: absolute,
	data-label-spacer: 1rem,
	data-label-text-align: center,
	data-label-top: 0,
	data-label-width: 1.5rem,
	data-label-before-content: unquote("'\\FEFF' attr(data-label-min)"),
	data-label-after-content: unquote("'\\FEFF' attr(data-label-max)"),
	data-label-after-right: 0,
	hover-cursor: $link-cursor,
	focus-outline: 0,
	focus-thumb-box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.25),
	disabled-color: $clay-range-disabled-color,
	disabled-cursor: $disabled-cursor,
	disabled-progress-bg: $primary-l2,
	disabled-track-bg: $gray-200,
), $clay-range-input);