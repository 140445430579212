$sticker-border-color: null !default;
$sticker-border-radius: $border-radius !default;
$sticker-border-style: null !default;
$sticker-border-width: null !default;
$sticker-color: $label-color !default;
$sticker-font-size: 0.875rem !default; // 14px
$sticker-font-weight: $font-weight-semi-bold !default;
$sticker-size: 2rem !default; // 32px

$sticker-inline-item-font-size: null !default;

// Sticker Sizes

$sticker-sm: () !default;
$sticker-sm: map-deep-merge((
	font-size: 0.75rem,
	size: 1.5rem
), $sticker-sm);

$sticker-lg: () !default;
$sticker-lg: map-deep-merge((
	font-size: 1.125rem,
	size: 2.5rem
), $sticker-lg);

$sticker-xl: () !default;
$sticker-xl: map-deep-merge((
	font-size: 1.25rem,
	size: 3rem
), $sticker-xl);

// Sticker Positions

$sticker-inside-offset: $grid-gutter-width / 2 !default;
$sticker-outside-offset: -($sticker-size / 2) !default;

// Sticker Circle

$sticker-circle-border-radius: $rounded-circle-border-radius !default;

// Sticker User Icon

$sticker-user-icon: () !default;
$sticker-user-icon: map-deep-merge((
	bg: $white,
	border-radius: $rounded-circle-border-radius,
	box-shadow: 0 0 0 1px rgba($black, 0.125),
), $sticker-user-icon);

// Sticker Variants

$sticker-primary-bg: $primary !default;
$sticker-primary-border-color: null !default;
$sticker-primary-color: color-yiq($sticker-primary-bg) !default;

$sticker-secondary-bg: $secondary !default;
$sticker-secondary-border-color: null !default;
$sticker-secondary-color: color-yiq($sticker-secondary-bg) !default;

$sticker-info-bg: $info !default;
$sticker-info-border-color: null !default;
$sticker-info-color: color-yiq($sticker-info-bg) !default;

$sticker-success-bg: $success !default;
$sticker-success-border-color: null !default;
$sticker-success-color: color-yiq($sticker-success-bg) !default;

$sticker-warning-bg: $warning !default;
$sticker-warning-border-color: null !default;
$sticker-warning-color: color-yiq($sticker-warning-bg) !default;

$sticker-danger-bg: $danger !default;
$sticker-danger-border-color: null !default;
$sticker-danger-color: color-yiq($sticker-danger-bg) !default;

$sticker-light-bg: $light !default;
$sticker-light-border-color: null !default;
$sticker-light-color: color-yiq($sticker-light-bg) !default;

$sticker-dark-bg: $dark !default;
$sticker-dark-border-color: null !default;
$sticker-dark-color: color-yiq($sticker-dark-bg) !default;

$sticker-palette: () !default;
$sticker-palette: map-deep-merge((
	primary: (
		bg: $sticker-primary-bg,
		border-color: $sticker-primary-border-color,
		color: $sticker-primary-color
	),
	secondary: (
		bg: $sticker-secondary-bg,
		border-color: $sticker-secondary-border-color,
		color: $sticker-secondary-color
	),
	success: (
		bg: $sticker-success-bg,
		border-color: $sticker-success-border-color,
		color: $sticker-success-color
	),
	info: (
		bg: $sticker-info-bg,
		border-color: $sticker-info-border-color,
		color: $sticker-info-color
	),
	warning: (
		bg: $sticker-warning-bg,
		border-color: $sticker-warning-border-color,
		color: $sticker-warning-color
	),
	danger: (
		bg: $sticker-danger-bg,
		border-color: $sticker-danger-border-color,
		color: $sticker-danger-color
	),
	light: (
		bg: $sticker-light-bg,
		border-color: $sticker-light-border-color,
		color: $sticker-light-color
	),
	dark: (
		bg: $sticker-dark-bg,
		border-color: $sticker-dark-border-color,
		color: $sticker-dark-color
	)
), $sticker-palette);