$modal-header-bg: null !default;
$modal-header-color: null !default;
$modal-header-height: 4rem !default; // 64px
$modal-header-height-mobile: null !default;

$modal-body-bg: null !default;
$modal-body-color: null !default;

$modal-footer-bg: null !default;
$modal-footer-box-shadow: null !default;
$modal-footer-color: null !default;
$modal-footer-height: null !default;
$modal-footer-padding-x: null !default;
$modal-footer-padding-y: 0.75rem !default; // 12px
$modal-footer-height-mobile: null !default;

$modal-item-padding-x: null !default;
$modal-item-padding-y: null !default;

$modal-title-font-size: 1.25rem !default; // 20px
$modal-title-font-weight: $font-weight-semi-bold !default;
$modal-title-text-align: null !default;
$modal-title-font-size-mobile: null !default;

$modal-title-indicator-font-size: 0.875rem !default; // 14px
$modal-title-indicator-spacer-x: 0.5rem !default; // 8px

// Modal Close

$modal-close-spacer-x: 0.3125rem !default; // 5px

// Modal Full Screen

$modal-full-screen-spacer-x: 45px !default;
$modal-full-screen-spacer-y: $modal-full-screen-spacer-x !default;

// Modal Height

$modal-height-sm: 250px !default;
$modal-height-md: 450px !default;
$modal-height-lg: 650px !default;
$modal-height-xl: 800px !default;

$modal-height-full-modal-content: () !default;
$modal-height-full-modal-content: map-deep-merge((
	position: absolute,
	bottom: $modal-dialog-margin,
	left: $modal-dialog-margin,
	right: $modal-dialog-margin,
	top: $modal-dialog-margin,
), $modal-height-full-modal-content);

$modal-height-full-modal-content-sm-up: () !default;
$modal-height-full-modal-content-sm-up: map-deep-merge((
	bottom: $modal-dialog-margin-y-sm-up,
	left: auto,
	right: auto,
	top: $modal-dialog-margin-y-sm-up,
), $modal-height-full-modal-content-sm-up);

// Modal Success

$modal-success: () !default;
$modal-success: map-deep-merge((
	header-bg: theme-color-level(success, -10),
	header-border-color: theme-color-level(success, -9),
	header-color: theme-color-level(success, 6),
	header-close: (
		color: theme-color-level(success, 6),
		hover-color: inherit,
		focus-color: inherit,
		disabled-color: inherit
	)
), $modal-success);

// Modal Info

$modal-info: () !default;
$modal-info: map-deep-merge((
	header-bg: theme-color-level(info, -10),
	header-border-color: theme-color-level(info, -9),
	header-color: theme-color-level(info, 6),
	header-close: (
		color: theme-color-level(info, 6),
		hover-color: inherit,
		focus-color: inherit,
		disabled-color: inherit
	)
), $modal-info);

// Modal Warning

$modal-warning: () !default;
$modal-warning: map-deep-merge((
	header-bg: theme-color-level(warning, -10),
	header-border-color: theme-color-level(warning, -9),
	header-color: theme-color-level(warning, 6),
	header-close: (
		color: theme-color-level(warning, 6),
		hover-color: inherit,
		focus-color: inherit,
		disabled-color: inherit
	)
), $modal-warning);

// Modal Danger

$modal-danger: () !default;
$modal-danger: map-deep-merge((
	header-bg: theme-color-level(danger, -10),
	header-border-color: theme-color-level(danger, -9),
	header-color: theme-color-level(danger, 6),
	header-close: (
		color: theme-color-level(danger, 6),
		hover-color: inherit,
		focus-color: inherit,
		disabled-color: inherit
	)
), $modal-danger);

$modal-palette: () !default;
$modal-palette: map-deep-merge((
	success: $modal-success,
	info: $modal-info,
	warning: $modal-warning,
	danger: $modal-danger
), $modal-palette);