////
/// @group labels
////

$label-color: null !default;

$label-link-color: inherit !default;
$label-link-hover-color: inherit !default;

$label-font-size: 75% !default;
$label-font-weight: $font-weight-bold !default;
$label-height: null !default;
$label-line-height: 1 !default;
$label-padding-x: 0.4375rem !default; // 7px
$label-padding-y: 0.1875rem !default; // 3px
$label-spacer-x: 0.25rem !default; // 4px
$label-spacer-y: 0.125rem !default; // 2px
$label-text-transform: null !default;

$label-lexicon-icon-height: 0.875em !default;
$label-lexicon-icon-margin-top: 0 !default;
$label-lexicon-icon-width: 0.875em !default;

$label-sticker-border-radius: 100px !default;
$label-sticker-size: 0.875em !default;

$label-dismissible-text-transform: null !default;

$label-item-spacer-x: 0.5em !default;
$label-item-spacer-y: null !default;

$label-anchor-text-decoration: none !default;
$label-anchor-hover-text-decoration: null !default;

$label-link-text-decoration: underline !default;
$label-link-hover-text-decoration: none !default;

$label-border-color: $gray-900 !default;
$label-border-radius: $border-radius !default;
$label-border-style: solid !default;
$label-border-width: 0.0625rem !default;

// Label Close

$label-close: () !default;
$label-close: map-deep-merge((
	color: inherit,
	opacity: 1,
	hover-color: inherit,
	hover-opacity: 1,
	focus-opacity: 1,
	disabled-opacity: $btn-disabled-opacity,
	border-radius: 1px,
	display: inline-flex,
	font-size: inherit,
	height: auto,
	width: auto
), $label-close);

// Label Sizes

$label-lg: () !default;
$label-lg: map-deep-merge((
	font-size: 0.875rem, // 14px
	padding-x: 1rem, // 16px
	padding-y: 0.375rem, // 6px
	text-transform: none
), $label-lg);

// Label Variants

$label-base: () !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-primary` instead

$label-primary-color: $primary !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-primary` instead

$label-primary-hover-color: darken($label-primary-color, 10%) !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-primary` instead

$label-primary-bg: $white !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-primary` instead

$label-primary-hover-bg: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-primary` instead

$label-primary-border-color: $label-primary-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-primary` instead

$label-primary-hover-border-color: $label-primary-hover-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-primary` instead

$label-primary-link-color: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-primary` instead

$label-primary-link-hover-color: $label-primary-hover-color !default;

$label-primary-close: () !default;
$label-primary-close: map-deep-merge((
	hover-color: $label-primary-hover-color,
	focus-color: $label-primary-hover-color,
), $label-primary-close);

$label-primary: () !default;
$label-primary: map-deep-merge((
	bg: $label-primary-bg,
	border-color: $label-primary-border-color,
	color: $label-primary-color,
	hover-bg: $label-primary-hover-bg,
	hover-border-color: $label-primary-hover-border-color,
	hover-color: $label-primary-hover-color,
	focus-color: $label-primary-hover-color,
	link-color: $label-primary-link-color,
	link-hover-color: $label-primary-link-hover-color,
	close: $label-primary-close,
), $label-primary);

/// @deprecated as of v2.4.1 use the Sass map `$label-secondary` instead

$label-secondary-color: $secondary !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-secondary` instead

$label-secondary-hover-color: darken($label-secondary-color, 10%) !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-secondary` instead

$label-secondary-bg: $white !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-secondary` instead

$label-secondary-hover-bg: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-secondary` instead

$label-secondary-border-color: $label-secondary-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-secondary` instead

$label-secondary-hover-border-color: $label-secondary-hover-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-secondary` instead

$label-secondary-link-color: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-secondary` instead

$label-secondary-link-hover-color: $label-secondary-hover-color !default;

$label-secondary-close: () !default;
$label-secondary-close: map-deep-merge((
	hover-color: $label-secondary-hover-color,
	focus-color: $label-secondary-hover-color,
), $label-secondary-close);

$label-secondary: () !default;
$label-secondary: map-deep-merge((
	bg: $label-secondary-bg,
	border-color: $label-secondary-border-color,
	color: $label-secondary-color,
	hover-bg: $label-secondary-hover-bg,
	hover-border-color: $label-secondary-hover-border-color,
	hover-color: $label-secondary-hover-color,
	focus-color: $label-secondary-hover-color,
	link-color: $label-secondary-link-color,
	link-hover-color: $label-secondary-link-hover-color,
	close: $label-secondary-close,
), $label-secondary);

/// @deprecated as of v2.4.1 use the Sass map `$label-success` instead

$label-success-color: $success !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-success` instead

$label-success-hover-color: darken($label-success-color, 10%) !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-success` instead

$label-success-bg: $white !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-success` instead

$label-success-hover-bg: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-success` instead

$label-success-border-color: $label-success-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-success` instead

$label-success-hover-border-color: $label-success-hover-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-success` instead

$label-success-link-color: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-success` instead

$label-success-link-hover-color: $label-success-hover-color !default;

$label-success-close: () !default;
$label-success-close: map-deep-merge((
	hover-color: $label-success-hover-color,
	focus-color: $label-success-hover-color,
), $label-success-close);

$label-success: () !default;
$label-success: map-deep-merge((
	bg: $label-success-bg,
	border-color: $label-success-border-color,
	color: $label-success-color,
	hover-bg: $label-success-hover-bg,
	hover-border-color: $label-success-hover-border-color,
	hover-color: $label-success-hover-color,
	focus-color: $label-success-hover-color,
	link-color: $label-success-link-color,
	link-hover-color: $label-success-link-hover-color,
	close: $label-success-close,
), $label-success);

/// @deprecated as of v2.4.1 use the Sass map `$label-info` instead

$label-info-color: $info !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-info` instead

$label-info-hover-color: darken($label-info-color, 10%) !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-info` instead

$label-info-bg: $white !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-info` instead

$label-info-hover-bg: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-info` instead

$label-info-border-color: $label-info-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-info` instead

$label-info-hover-border-color: $label-info-hover-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-info` instead

$label-info-link-color: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-info` instead

$label-info-link-hover-color: $label-info-hover-color !default;

$label-info-close: () !default;
$label-info-close: map-deep-merge((
	hover-color: $label-info-hover-color,
	focus-color: $label-info-hover-color,
), $label-info-close);

$label-info: () !default;
$label-info: map-deep-merge((
	bg: $label-info-bg,
	border-color: $label-info-border-color,
	color: $label-info-color,
	hover-bg: $label-info-hover-bg,
	hover-border-color: $label-info-hover-border-color,
	hover-color: $label-info-hover-color,
	focus-color: $label-info-hover-color,
	link-color: $label-info-link-color,
	link-hover-color: $label-info-link-hover-color,
	close: $label-info-close,
), $label-info);

/// @deprecated as of v2.4.1 use the Sass map `$label-warning` instead

$label-warning-color: $warning !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-warning` instead

$label-warning-hover-color: darken($label-warning-color, 10%) !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-warning` instead

$label-warning-bg: $white !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-warning` instead

$label-warning-hover-bg: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-warning` instead

$label-warning-border-color: $label-warning-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-warning` instead

$label-warning-hover-border-color: $label-warning-hover-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-warning` instead

$label-warning-link-color: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-warning` instead

$label-warning-link-hover-color: $label-warning-hover-color !default;

$label-warning-close: () !default;
$label-warning-close: map-deep-merge((
	hover-color: $label-warning-hover-color,
	focus-color: $label-warning-hover-color,
), $label-warning-close);

$label-warning: () !default;
$label-warning: map-deep-merge((
	bg: $label-warning-bg,
	border-color: $label-warning-border-color,
	color: $label-warning-color,
	hover-bg: $label-warning-hover-bg,
	hover-border-color: $label-warning-hover-border-color,
	hover-color: $label-warning-hover-color,
	focus-color: $label-warning-hover-color,
	link-color: $label-warning-link-color,
	link-hover-color: $label-warning-link-hover-color,
	close: $label-warning-close,
), $label-warning);

/// @deprecated as of v2.4.1 use the Sass map `$label-danger` instead

$label-danger-color: $danger !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-danger` instead

$label-danger-hover-color: darken($label-danger-color, 10%) !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-danger` instead

$label-danger-bg: $white !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-danger` instead

$label-danger-hover-bg: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-danger` instead

$label-danger-border-color: $label-danger-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-danger` instead

$label-danger-hover-border-color: $label-danger-hover-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-danger` instead

$label-danger-link-color: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-danger` instead

$label-danger-link-hover-color: $label-danger-hover-color !default;

$label-danger-close: () !default;
$label-danger-close: map-deep-merge((
	hover-color: $label-danger-hover-color,
	focus-color: $label-danger-hover-color,
), $label-danger-close);

$label-danger: () !default;
$label-danger: map-deep-merge((
	bg: $label-danger-bg,
	border-color: $label-danger-border-color,
	color: $label-danger-color,
	hover-bg: $label-danger-hover-bg,
	hover-border-color: $label-danger-hover-border-color,
	hover-color: $label-danger-hover-color,
	focus-color: $label-danger-hover-color,
	link-color: $label-danger-link-color,
	link-hover-color: $label-danger-link-hover-color,
	close: $label-danger-close,
), $label-danger);

/// @deprecated as of v2.4.1 use the Sass map `$label-light` instead

$label-light-color: $light !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-light` instead

$label-light-hover-color: darken($label-light-color, 10%) !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-light` instead

$label-light-bg: $gray-800 !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-light` instead

$label-light-hover-bg: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-light` instead

$label-light-border-color: $label-light-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-light` instead

$label-light-hover-border-color: $label-light-hover-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-light` instead

$label-light-link-color: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-light` instead

$label-light-link-hover-color: $label-light-hover-color !default;

$label-light-close: () !default;
$label-light-close: map-deep-merge((
	hover-color: $label-light-hover-color,
	focus-color: $label-light-hover-color,
), $label-light-close);

$label-light: () !default;
$label-light: map-deep-merge((
	bg: $label-light-bg,
	border-color: $label-light-border-color,
	color: $label-light-color,
	hover-bg: $label-light-hover-bg,
	hover-border-color: $label-light-hover-border-color,
	hover-color: $label-light-hover-color,
	focus-color: $label-light-hover-color,
	link-color: $label-light-link-color,
	link-hover-color: $label-light-link-hover-color,
	close: $label-light-close,
), $label-light);

/// @deprecated as of v2.4.1 use the Sass map `$label-dark` instead

$label-dark-color: $dark !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-dark` instead

$label-dark-hover-color: darken($label-dark-color, 10%) !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-dark` instead

$label-dark-bg: $white !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-dark` instead

$label-dark-hover-bg: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-dark` instead

$label-dark-border-color: $label-dark-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-dark` instead

$label-dark-hover-border-color: $label-dark-hover-color !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-dark` instead

$label-dark-link-color: null !default;

/// @deprecated as of v2.4.1 use the Sass map `$label-dark` instead

$label-dark-link-hover-color: $label-dark-hover-color !default;

$label-dark-close: () !default;
$label-dark-close: map-deep-merge((
	hover-color: $label-dark-hover-color,
	focus-color: $label-dark-hover-color,
), $label-dark-close);

$label-dark: () !default;
$label-dark: map-deep-merge((
	bg: $label-dark-bg,
	border-color: $label-dark-border-color,
	color: $label-dark-color,
	hover-bg: $label-dark-hover-bg,
	hover-border-color: $label-dark-hover-border-color,
	hover-color: $label-dark-hover-color,
	focus-color: $label-dark-hover-color,
	link-color: $label-dark-link-color,
	link-hover-color: $label-dark-link-hover-color,
	close: $label-dark-close,
), $label-dark);

$label-palette: () !default;
$label-palette: map-deep-merge((
	primary: $label-primary,
	secondary: $label-secondary,
	success: $label-success,
	info: $label-info,
	warning: $label-warning,
	danger: $label-danger,
	dark: $label-dark,
	light: $label-light,
), $label-palette);