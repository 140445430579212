$application-bar-size: () !default;
$application-bar-size: map-deep-merge((
	scaling-navbar: true,
	font-size: $navbar-font-size,
	height: 3.5rem,
	height-mobile: 3rem,
	btn-monospaced-font-size: 1rem,
	link-height: 2rem,
	link-height-mobile: 2rem,
	link-margin-x: 0.5rem,
	link-padding-x: 0.25rem,
	padding-x: 0,
	padding-y: 0
), $application-bar-size);

$application-bar-base: () !default;

$application-bar-dark: () !default;
$application-bar-dark: map-deep-merge((
	bg: $dark,
	color: $navbar-dark-color,
	link-color: $navbar-dark-color,
	link-hover-color: $navbar-dark-hover-color,
	link-active-color: $navbar-dark-active-color,
	link-disabled-color: $navbar-dark-disabled-color,
	link-disabled-opacity: 1
), $application-bar-dark);