// Simple Toggle Switch

.simple-toggle-switch {
	&.toggle-switch {
		align-items: center;
		display: inline-flex;
	}

	.toggle-switch-check + .toggle-switch-label {
		margin-right: $simple-toggle-switch-label-spacer-x;
	}

	.toggle-switch-label + .toggle-switch-check-bar {
		margin-left: $simple-toggle-switch-label-spacer-x;
	}

	.toggle-switch-label {
		line-height: $simple-toggle-switch-label-line-height;
		margin-bottom: 0;
		max-width: calc(100% - #{clay-data-label-text-position($toggle-switch-bar-width, $toggle-switch-bar-padding)});

		@include clay-scale-component {
			max-width: calc(100% - #{clay-data-label-text-position($toggle-switch-bar-width-mobile, $toggle-switch-bar-padding-mobile)});
		}
	}
}

.simple-toggle-switch-reverse {
	.toggle-switch-label {
		margin-right: $simple-toggle-switch-label-spacer-x;
	}

	.toggle-switch-check-bar {
		order: 5;

		.toggle-switch-bar {
			order: 0;
		}
	}

	.toggle-switch-bar {
		order: 5;
	}
}

// Toggle Switch

label.toggle-switch {
	cursor: $toggle-switch-cursor;

	&.disabled {
		cursor: $toggle-switch-disabled-cursor;
	}
}

.toggle-switch {
	display: inline-block;
	font-weight: $toggle-switch-font-weight;
	max-width: 100%;
	position: relative;

	&.disabled {
		.toggle-switch-label {
			color: $toggle-switch-label-disabled-color;
			cursor: $toggle-switch-disabled-cursor;
		}

		.toggle-switch-text {
			color: $toggle-switch-text-disabled-color;
		}
	}
}

.toggle-switch-check-bar {
	display: inline-flex;
	position: relative;
}

.toggle-switch-bar {
	.toggle-switch-handle {
		display: block;
		min-width: $toggle-switch-bar-width;
		text-transform: uppercase;
	}

	.toggle-switch-icon {
		font-size: $toggle-switch-bar-font-size;

		.lexicon-icon {
			margin-top: -0.2em;
		}
	}

	.button-icon {
		font-size: $toggle-switch-button-font-size;
	}
}

.toggle-switch-check {
	bottom: 0;
	font-size: 62.5%;
	height: $toggle-switch-bar-height;
	opacity: 0;
	position: absolute;
	width: $toggle-switch-bar-width;
	z-index: 2;

	@include clay-scale-component {
		height: $toggle-switch-bar-height-mobile;
		width: $toggle-switch-bar-width-mobile;
	}

	&:empty ~ .toggle-switch-bar {
		display: inline-flex;
		font-size: $toggle-switch-bar-font-size;
		height: $toggle-switch-bar-height;
		line-height: $toggle-switch-bar-height;
		position: relative;
		text-indent: 0;

		-ms-user-select: none;
		user-select: none;

		&::after {
			background-color: $toggle-switch-button-bg;
			border-color: $toggle-switch-button-border-color;

			@include border-radius($toggle-switch-button-border-radius);

			border-style: solid;
			border-width: $toggle-switch-button-border-width;
			bottom: $toggle-switch-bar-padding;
			content: '';
			display: block;
			height: $toggle-switch-button-width;
			left: $toggle-switch-bar-padding;
			position: absolute;
			top: $toggle-switch-bar-padding;
			transition: $toggle-switch-transition;
			width: $toggle-switch-button-width;
		}

		&::before {
			background-color: $toggle-switch-bar-bg;
			border-color: $toggle-switch-bar-border-color;

			@include border-radius($toggle-switch-bar-border-radius);

			border-style: solid;
			border-width: $toggle-switch-bar-border-width;
			bottom: 0;
			content: ' ';
			display: block;
			left: 0;
			position: absolute;
			top: 0;
			transition: $toggle-switch-transition;
			width: $toggle-switch-bar-width;
		}

		.toggle-switch-handle {
			&::after {
				content: $toggle-switch-handle-after-content;
				margin-left: clay-data-label-text-position($toggle-switch-bar-width, $toggle-switch-bar-padding);
				transition: $toggle-switch-transition;
				white-space: nowrap;
			}

			&::before {
				transition: $toggle-switch-transition;
			}
		}

		.toggle-switch-icon {
			color: $toggle-switch-bar-icon-color;
			left: $toggle-switch-bar-padding;
			line-height: $toggle-switch-button-width;
			position: absolute;
			text-align: center;
			text-indent: 0;
			top: $toggle-switch-bar-padding;
			transition: $toggle-switch-transition;
			width: $toggle-switch-button-width;
			z-index: 1;
		}

		.toggle-switch-icon-on {
			left: $toggle-switch-bar-padding;
			opacity: 0;
		}

		.toggle-switch-icon-off {
			left: $toggle-switch-bar-width - $toggle-switch-bar-padding - $toggle-switch-button-width;
		}

		.button-icon {
			color: $toggle-switch-button-icon-color;
		}

		.button-icon-on {
			opacity: 0;
		}
	}

	&:checked ~ .toggle-switch-bar {
		&::after {
			background-color: $toggle-switch-button-on-bg;
			border-color: $toggle-switch-button-on-border-color;

			@include border-radius($toggle-switch-button-on-border-radius);

			border-style: solid;
			border-width: $toggle-switch-button-border-width;
			left: $toggle-switch-bar-width - $toggle-switch-bar-padding - $toggle-switch-button-width;
		}

		&::before {
			background-color: $toggle-switch-bar-on-bg;
			border-color: $toggle-switch-bar-on-border-color;

			@include border-radius($toggle-switch-bar-border-radius);

			border-style: solid;
			border-width: $toggle-switch-bar-border-width;
		}

		.toggle-switch-handle::after {
			content: $toggle-switch-handle-on-after-content;
		}

		.toggle-switch-icon {
			color: $toggle-switch-bar-on-icon-color;
		}

		.button-icon {
			color: $toggle-switch-button-on-icon-color;
			left: $toggle-switch-bar-width - $toggle-switch-bar-padding - $toggle-switch-button-width;
		}

		.button-icon-on,
		.toggle-switch-icon-on {
			opacity: 1;
		}

		.button-icon-off,
		.toggle-switch-icon-off {
			opacity: 0;
		}
	}

	&:disabled,
	&.disabled {
		~ .toggle-switch-bar {
			cursor: $toggle-switch-disabled-cursor;
			opacity: $toggle-switch-disabled-opacity;
		}
	}

	&:focus ~ .toggle-switch-bar::before {
		box-shadow: $toggle-switch-bar-focus-box-shadow;
	}
}

.toggle-switch-label {
	display: block;
	margin-bottom: 2px;
}

.toggle-switch-text {
	display: block;
	font-size: $toggle-switch-text-font-size;
}

.toggle-switch-text-left {
	display: inline-flex;
	line-height: $toggle-switch-bar-height;
	margin-right: 8px;
}

.toggle-switch-text-right {
	display: inline-flex;
	line-height: $toggle-switch-bar-height;
	margin-left: 8px;
}

@include clay-scale-component {
	.toggle-switch-bar {
		.toggle-switch-handle {
			min-width: $toggle-switch-bar-width-mobile;
		}

		.toggle-switch-icon {
			font-size: $toggle-switch-bar-font-size-mobile;
		}

		.button-icon {
			font-size: $toggle-switch-button-font-size-mobile;
		}
	}

	.toggle-switch-check {
		&:empty ~ .toggle-switch-bar {
			height: $toggle-switch-bar-height-mobile;
			line-height: $toggle-switch-bar-height-mobile;
			text-indent: 0;

			&::after {
				bottom: $toggle-switch-bar-padding-mobile;
				height: $toggle-switch-button-width-mobile;
				left: $toggle-switch-bar-padding-mobile;
				top: $toggle-switch-bar-padding-mobile;
				width: $toggle-switch-button-width-mobile;
			}

			&::before {
				width: $toggle-switch-bar-width-mobile;
			}

			.toggle-switch-handle::after {
				margin-left: clay-data-label-text-position($toggle-switch-bar-width-mobile, $toggle-switch-bar-padding-mobile);
			}

			.toggle-switch-icon {
				left: $toggle-switch-bar-padding-mobile;
				line-height: $toggle-switch-button-width-mobile;
				top: $toggle-switch-bar-padding-mobile;
				width: $toggle-switch-button-width-mobile;
			}

			.toggle-switch-icon-on {
				left: $toggle-switch-bar-padding-mobile;
			}

			.toggle-switch-icon-off {
				left: $toggle-switch-bar-width-mobile - $toggle-switch-bar-padding-mobile - $toggle-switch-button-width-mobile;
			}
		}

		&:checked ~ .toggle-switch-bar {
			&::after {
				left: $toggle-switch-bar-width-mobile - $toggle-switch-bar-padding-mobile - $toggle-switch-button-width-mobile;
			}

			.toggle-switch-handle::after {
				margin-left: clay-data-label-text-position($toggle-switch-bar-width-mobile, $toggle-switch-bar-padding-mobile);
			}

			.button-icon {
				left: $toggle-switch-bar-width-mobile - $toggle-switch-bar-padding-mobile - $toggle-switch-button-width-mobile;
			}
		}
	}

	.toggle-switch-text-left,
	.toggle-switch-text-right {
		line-height: $toggle-switch-bar-height-mobile;
	}
}