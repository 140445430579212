$input-font-size-mobile: 1rem !default; // 16px

$input-label-font-size: 0.875rem !default; // 14px
$input-label-font-weight: $font-weight-semi-bold !default;
$input-label-margin-bottom: 0.25rem !default; // 4px

$input-label-reference-mark-font-size: 6px !default;

$input-border-width: 0.0625rem !default; // 1px

$input-border-bottom-width: $input-border-width !default; // 1px
$input-border-left-width: $input-border-width !default; // 1px
$input-border-right-width: $input-border-width !default; // 1px
$input-border-top-width: $input-border-width !default; // 1px

$input-border-style: solid !default;

$input-border-radius-sm: $border-radius !default;

$input-padding-x: 1rem !default; // 16px
$input-padding-y: 0.4375rem !default; // 7px

$input-padding-x-lg: $input-padding-x !default;
$input-padding-y-lg: $input-padding-y !default;

$input-padding-x-sm: 0.75rem !default; // 12px
$input-padding-y-sm: 0.25rem !default;

$input-height-border: $input-border-bottom-width + $input-border-top-width !default;
$input-height: 2.5rem !default; // 40px
$input-height-inner: $input-height - $input-height-border !default;
$input-height-lg: 3rem !default; // 48px
$input-height-inner-lg: $input-height-lg - $input-height-border !default;
$input-height-sm: 2rem !default; // 32px
$input-height-inner-sm: $input-height-sm - $input-height-border !default;

$form-feedback-font-size: 0.875rem !default; // 14px

$form-text-color: $gray-600 !default;

// Form Group

$form-group-margin-bottom: 1.5rem !default; // 24px
$form-group-margin-bottom-mobile: 1rem !default; // 16px

$form-group-sm-input-label-margin-bottom: 0.1875rem !default; // 3px
$form-group-sm-item-label-spacer: 1.5625rem !default; // 25px

// Skin

$input-color: $gray-900 !default;
$input-bg: $gray-200 !default;
$input-border-color: $gray-300 !default;
// Will need to be revisited if https://github.com/twbs/bootstrap/pull/24821 merge error is fixed
$input-box-shadow: 0 0 rgba(0, 0, 0, 0) !default;
$input-placeholder-color: $gray-600 !default;
$input-label-color: $gray-900 !default;

$input-focus-bg: lighten($component-active-bg, 44.90) !default;
$input-focus-border-color: lighten($component-active-bg, 22.94) !default;
$input-focus-box-shadow: $component-focus-box-shadow !default;

$input-disabled-color: $gray-500 !default;
$input-disabled-bg: $input-bg !default;
$input-disabled-border-color: $input-bg !default;
$input-placeholder-disabled-color: $input-disabled-color !default;

$input-label-disabled-color: $gray-500 !default;

// @deprecated after v2.18.0 use the Sass map `$input-readonly` instead

$input-readonly-bg: $white !default;

$input-readonly: () !default;
$input-readonly: map-deep-merge((
	bg: $input-readonly-bg,
	border-color: $input-border-color,
	focus-box-shadow: none,
), $input-readonly);

$input-plaintext-readonly: () !default;
$input-plaintext-readonly: map-deep-merge((
	focus-border-color: $input-focus-border-color,
	focus-box-shadow: none,
), $input-plaintext-readonly);

$input-danger-bg: $danger-l2 !default;
$input-danger-border-color: $danger-l1 !default;
// Will need to be revisited if https://github.com/twbs/bootstrap/pull/24821 merge error is fixed
$input-danger-box-shadow: 0 0 rgba(0, 0, 0, 0) !default;
$input-danger-focus-box-shadow: $input-focus-box-shadow !default;
$input-danger-color: $input-color !default;

$input-danger-readonly: () !default;
$input-danger-readonly: map-deep-merge((
	bg: map-get($input-readonly, bg),
	border-color: map-get($input-readonly, border-color),
	focus-border-color: $input-focus-border-color,
), $input-danger-readonly);

$input-danger-checkbox-label-color: $danger !default;
$input-danger-select-icon-color: $input-danger-border-color !default;
$input-danger-select-icon: clay-icon(caret-double-l, $input-danger-select-icon-color) !default;

$input-success-bg: $success-l2 !default;
$input-success-border-color: $success-l1 !default;
// Will need to be revisited if https://github.com/twbs/bootstrap/pull/24821 merge error is fixed
$input-success-box-shadow: 0 0 rgba(0, 0, 0, 0) !default;
$input-success-focus-box-shadow: $input-focus-box-shadow !default;
$input-success-color: $input-color !default;

$input-success-readonly: () !default;
$input-success-readonly: map-deep-merge((
	bg: map-get($input-readonly, bg),
	border-color: map-get($input-readonly, border-color),
	focus-border-color: $input-focus-border-color,
), $input-success-readonly);

$input-success-checkbox-label-color: $success !default;
$input-success-select-icon-color: $input-success-border-color !default;
$input-success-select-icon: clay-icon(caret-double-l, $input-success-select-icon-color) !default;

$input-warning-bg: $warning-l2 !default;
$input-warning-border-color: $warning-l1 !default;
// Will need to be revisited if https://github.com/twbs/bootstrap/pull/24821 merge error is fixed
$input-warning-box-shadow: 0 0 rgba(0, 0, 0, 0) !default;
$input-warning-focus-box-shadow: $input-focus-box-shadow !default;

$input-warning-readonly: () !default;
$input-warning-readonly: map-deep-merge((
	bg: map-get($input-readonly, bg),
	border-color: map-get($input-readonly, border-color),
	focus-border-color: $input-focus-border-color,
), $input-warning-readonly);

$input-warning-color: $input-color !default;
$input-warning-checkbox-label-color: $warning !default;
$input-warning-select-icon-color: $input-warning-border-color !default;
$input-warning-select-icon: clay-icon(caret-double-l, $input-warning-select-icon-color) !default;

// Select Element

$input-select-bg-position: right 0.5em center !default;
$input-select-bg-size: 1.5em 1.5em !default;
$input-select-padding-right: 2em !default;

$input-select-icon-color: $gray-600 !default;
$input-select-icon: clay-icon(caret-double-l, $input-select-icon-color) !default;

$input-select-icon-focus-color: $input-select-icon-color !default;
$input-select-icon-focus: clay-icon(caret-double-l, $input-select-icon-focus-color) !default;

$input-select-icon-disabled-color: $gray-500 !default;
$input-select-icon-disabled: clay-icon(caret-double-l, $input-select-icon-disabled-color) !default;

// Form Feedback

$form-feedback-font-weight: $font-weight-semi-bold !default;

$form-feedback-indicator-margin-x: 0 !default;

$form-text-font-weight: $font-weight-semi-bold !default;

// Textarea

$input-textarea-height: 100px !default;
$input-textarea-height-lg: 120px !default;
$input-textarea-height-sm: 80px !default;

// Form Control Label (Labels inside Form Control Tag Group)

$form-control-label-size: () !default;
$form-control-label-size: map-deep-merge((
	border-width: 0.0625rem, // 1px
	font-size: map-get($label-lg, font-size),
	height: 1.5rem, // 24px
	margin-bottom: 0.3125rem, // 5px
	margin-right: 0.625rem, // 10px
	margin-top: 0.3125rem, // 5px
	padding-x: map-get($label-lg, padding-x),
	padding-y: map-get($label-lg, padding-y),
	text-transform: none,
	item-spacer-y: map-get($label-lg, item-spacer-y), // -2px
	lexicon-icon-height: map-get($label-lg, lexicon-icon-height), // 16px
	lexicon-icon-width: map-get($label-lg, lexicon-icon-width), // 16px
	sticker-size: map-get($label-lg, sticker-size), // 16px
	close: map-get($label-lg, close)
), $form-control-label-size);

// Form Group

$form-group-item-label-spacer: ($input-label-font-size * $line-height-base) + $input-label-margin-bottom !default;

// Input Groups

$input-group-addon-bg: $gray-300 !default;
$input-group-addon-border-color: $input-group-addon-bg !default;
$input-group-addon-color: $gray-600 !default;
$input-group-addon-font-weight: $font-weight-semi-bold !default;
$input-group-addon-min-width: 2.5rem !default; // 40px
$input-group-addon-padding-x: 0.75rem !default; // 12px

// Input Group Sizes

$input-group-addon-min-width-sm: 2rem !default; // 32px

// Input Group Secondary

$input-group-secondary-addon-bg: $white !default;
$input-group-secondary-addon-border-color: $secondary-l2 !default;
$input-group-secondary-addon-color: $secondary !default;