$application-bar-size: () !default;
$application-bar-size: map-deep-merge((
	title-font-size: 1.125rem // 18px
), $application-bar-size);

$application-bar-base: () !default;
$application-bar-base: map-deep-merge((
	link-border-radius: $border-radius,
	link-outline: 0,
	link-transition: box-shadow 0.15s ease-in-out,
	link-focus-box-shadow: $btn-focus-box-shadow,
	link-disabled-box-shadow: none,
), $application-bar-base);

$application-bar-dark: () !default;
$application-bar-dark: map-deep-merge((
	bg: $dark-l1,
	link-font-weight: $font-weight-semi-bold,
	link-hover-bg: rgba(255, 255, 255, 0.03),
	link-focus-bg: rgba(255, 255, 255, 0.03),
	link-active-bg: rgba(255, 255, 255, 0.06),
	link-disabled-bg: transparent,
	link-disabled-opacity: 0.5,
), $application-bar-dark);