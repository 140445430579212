$sheet-bg: $white !default;
$sheet-border-color: $gray-300 !default;

$sheet-padding-bottom-mobile: null !default;
$sheet-padding-left-mobile: 1rem !default; // 16px
$sheet-padding-right-mobile: 1rem !default; // 16px
$sheet-padding-top-mobile: 1rem !default; // 16px

$sheet-header-margin-bottom-mobile: 2rem !default; // 32px

$sheet-section-margin-bottom-mobile: 2rem !default; // 32px

$sheet-panel-group-margin-bottom-mobile: 1rem !default; // 16px

$sheet-footer-margin-bottom-mobile: 1rem !default; // 16px
$sheet-footer-margin-top-mobile: 1rem !default; // 16px

// Sheet Title

$sheet-title-font-size: 1.25rem !default; // 20px
$sheet-title-font-weight: $font-weight-bold !default;
$sheet-title-margin-bottom-mobile: 1rem !default; // 16px

// Sheet Subtitle

$sheet-subtitle-color: $body-color !default;
$sheet-subtitle-border-color: $gray-500 !default;
$sheet-subtitle-font-size: 0.875rem !default; // 14px
$sheet-subtitle-padding-y: 0.5rem !default; // 8px
$sheet-subtitle-margin-bottom-mobile: 1rem !default; // 16px

// Sheet Subtitle as Panel Header

$sheet-subtitle-link: () !default;
$sheet-subtitle-link: map-deep-merge((
	border-radius: 1px,
	transition: box-shadow 0.15s ease-in-out,
	focus-box-shadow: 0 0 0 0.25rem $white#{','} 0 0 0 0.375rem $primary-l1,
	focus-outline: 0,
), $sheet-subtitle-link);

$sheet-subtitle-collapse-icon-font-size: 0.75rem !default;

// Sheet Tertiary Title

$sheet-tertiary-title-margin-bottom-mobile: 0.5rem !default; // 8px

$sheet-text-color: $gray-600 !default;

$sheet-text-margin-bottom-mobile: 1rem !default; // 16px