////
/// @group loaders
////

/// A mixin for creating and customizing the Clay CSS Loading Indicator.
/// @param {Map} $map - A map of `key: value` pairs. The keys and value types are listed below:
/// @example
/// enabled: {Bool}, // Set to false to prevent mixin styles from being output. Default: true
/// border-radius: {Number | String | List | Null}, // Default: 50%
/// color: {Color}, // Default: $secondary
/// transform: {String | List | Null}, // Default: scale(0.33334)
/// animation: {String | List | Null}, // Default: 1.2s ease-out infinite
/// animation-name: {String}, // *required
/// ball-0-scale: {Number}, // Default: 0
/// ball-1-scale: {Number}, // Default: -0.10em
/// ball-2-scale: {Number}, // Default: -0.15em
/// ball-3-scale: {Number}, // Default: -0.20em
/// ball-4-scale: {Number}, // Default: -0.25em
/// ball-5-scale: {Number}, // Default: -0.35em
/// ball-0-alpha: {Number}, // Default: 1
/// ball-1-alpha: {Number}, // Default: 0.80
/// ball-2-alpha: {Number}, // Default: 0.60
/// ball-3-alpha: {Number}, // Default: 0.40
/// ball-4-alpha: {Number}, // Default: 0.20
/// ball-5-alpha: {Number}, // Default: 0.10
/// ball-0-color: {Color} // Default: rgba($color, $ball-0-alpha)
/// ball-1-color: {Color}, // Default: rgba($color, $ball-1-alpha)
/// ball-2-color: {Color}, // Default: rgba($color, $ball-2-alpha)
/// ball-3-color: {Color}, // Default: rgba($color, $ball-3-alpha)
/// ball-4-color: {Color}, // Default: rgba($color, $ball-4-alpha)
/// ball-5-color: {Color}, // Default: rgba($color, $ball-5-alpha)
/// @todo
/// - Add @example
/// - Add @link to documentation

@mixin clay-loading-animation-variant($map) {
	$enabled: setter(map-get($map, enabled), true);
	$mixin-name: 'clay-loading-animation-variant';

	$border-radius: setter(map-get($map, border-radius), 50%);
	$color: setter(map-get($map, color), $secondary);
	$transform: setter(map-get($map, transform), scale(0.33334));

	$animation: setter(map-get($map, animation), 1.2s ease-out infinite);
	$animation-name: required(
		map-get($map, animation-name),
		'The property `animation-name` in `#{$mixin-name}` is required!'
	);

	$ball-0-scale: setter(map-get($map, ball-0-scale), 0em);
	$ball-1-scale: setter(map-get($map, ball-1-scale), -0.10em);
	$ball-2-scale: setter(map-get($map, ball-2-scale), -0.15em);
	$ball-3-scale: setter(map-get($map, ball-3-scale), -0.20em);
	$ball-4-scale: setter(map-get($map, ball-4-scale), -0.25em);
	$ball-5-scale: setter(map-get($map, ball-5-scale), -0.35em);

	$ball-0-alpha: setter(map-get($map, ball-0-alpha), 1);
	$ball-1-alpha: setter(map-get($map, ball-1-alpha), 0.80);
	$ball-2-alpha: setter(map-get($map, ball-2-alpha), 0.60);
	$ball-3-alpha: setter(map-get($map, ball-3-alpha), 0.40);
	$ball-4-alpha: setter(map-get($map, ball-4-alpha), 0.20);
	$ball-5-alpha: setter(map-get($map, ball-5-alpha), 0.10);

	$ball-0-color: setter(map-get($map, ball-0-color), rgba($color, $ball-0-alpha));
	$ball-1-color: setter(map-get($map, ball-1-color), rgba($color, $ball-1-alpha));
	$ball-2-color: setter(map-get($map, ball-2-color), rgba($color, $ball-2-alpha));
	$ball-3-color: setter(map-get($map, ball-3-color), rgba($color, $ball-3-alpha));
	$ball-4-color: setter(map-get($map, ball-4-color), rgba($color, $ball-4-alpha));
	$ball-5-color: setter(map-get($map, ball-5-color), rgba($color, $ball-5-alpha));

	@if ($enabled) {
		&:after {
			animation: $animation;
			animation-name: $animation-name;
			border-radius: $border-radius;
			content: '';

			// Box shadow needs this specific order for proper animation

			box-shadow: 0 -1em 0 $ball-0-scale $ball-0-color,
				1em -0.5em 0 $ball-5-scale $ball-5-color,
				1em 0.5em 0 $ball-4-scale $ball-4-color,
				0 1em 0 $ball-3-scale $ball-3-color,
				-1em 0.5em 0 $ball-2-scale $ball-2-color,
				-1em -0.5em 0 $ball-1-scale $ball-1-color;
			display: block;
			height: 1em;
			left: 0;
			position: absolute;
			top: 0;
			transform: $transform;
			width: 1em;
		}

		@keyframes #{$animation-name} {

			// Box shadows need this specific order for proper animation

			0%,
			100% {
				box-shadow: 0 -1em 0 $ball-0-scale $ball-0-color,
				1em -0.5em 0 $ball-5-scale $ball-5-color,
				1em 0.5em 0 $ball-4-scale $ball-4-color,
				0 1em 0 $ball-3-scale $ball-3-color,
				-1em 0.5em 0 $ball-2-scale $ball-2-color,
				-1em -0.5em 0 $ball-1-scale $ball-1-color;
			}
			16.6% {
				box-shadow: 0 -1em 0 $ball-1-scale $ball-1-color,
				1em -0.5em 0 $ball-0-scale $ball-0-color,
				1em 0.5em 0 $ball-5-scale $ball-5-color,
				0 1em 0 $ball-4-scale $ball-4-color,
				-1em 0.5em 0 $ball-3-scale $ball-3-color,
				-1em -0.5em 0 $ball-2-scale $ball-2-color;
			}
			33.3% {
				box-shadow: 0 -1em 0 $ball-2-scale $ball-2-color,
				1em -0.5em 0 $ball-1-scale $ball-1-color,
				1em 0.5em 0 $ball-0-scale $ball-0-color,
				0 1em 0 $ball-5-scale $ball-5-color,
				-1em 0.5em 0 $ball-4-scale $ball-4-color,
				-1em -0.5em 0 $ball-3-scale $ball-3-color;
			}
			50% {
				box-shadow: 0 -1em 0 $ball-3-scale $ball-3-color,
				1em -0.5em 0 $ball-2-scale $ball-2-color,
				1em 0.5em 0 $ball-1-scale $ball-1-color,
				0 1em 0 $ball-0-scale $ball-0-color,
				-1em 0.5em 0 $ball-5-scale $ball-5-color,
				-1em -0.5em 0 $ball-4-scale $ball-4-color;
			}
			66.6% {
				box-shadow: 0 -1em 0 $ball-4-scale $ball-4-color,
				1em -0.5em 0 $ball-3-scale $ball-3-color,
				1em 0.5em 0 $ball-2-scale $ball-2-color,
				0 1em 0 $ball-1-scale $ball-1-color,
				-1em 0.5em 0 $ball-0-scale $ball-0-color,
				-1em -0.5em 0 $ball-5-scale $ball-5-color;
			}
			83% {
				box-shadow: 0 -1em 0 $ball-5-scale $ball-5-color,
				1em -0.5em 0 $ball-4-scale $ball-4-color,
				1em 0.5em 0 $ball-3-scale $ball-3-color,
				0 1em 0 $ball-2-scale $ball-2-color,
				-1em 0.5em 0 $ball-1-scale $ball-1-color,
				-1em -0.5em 0 $ball-0-scale $ball-0-color;
			}
		}
	}
}