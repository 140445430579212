.menubar {
	position: relative;
}

.menubar-toggler {
	@extend %btn-unstyled !optional;

	display: none;
}

// Menubar Vertical MD

.menubar-vertical-expand-md {
	@include clay-menubar-vertical-expand($menubar-vertical-expand-md);

	&.menubar-transparent {
		@include clay-menubar-vertical-variant($menubar-vertical-transparent-md);
	}
}

// Menubar Vertical LG

.menubar-vertical-expand-lg {
	@include clay-menubar-vertical-expand($menubar-vertical-expand-lg);

	&.menubar-transparent {
		@include clay-menubar-vertical-variant($menubar-vertical-transparent-lg);
	}
}