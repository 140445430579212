////
/// @group globals
////

/// @deprecated as of v2.4.1 we will not support unsetting variables in version 2 because it violates the Sass spec. If you are using libsass you can still unset Clay CSS / Bootstrap variables with the syntax `$my-var: !default;`.

$clay-unset: clay-unset !default;
$clay-unset-placeholder: clay-unset-placeholder !default;

$primary-d1: darken($primary, 7.5) !default;
$primary-d2: darken($primary, 10) !default;
$primary-l1: lighten($primary, 22.94) !default;
$primary-l2: lighten($primary, 32.94) !default;
$primary-l3: lighten($primary, 44.90) !default;

$secondary-d1: darken($secondary, 7.5) !default;
$secondary-d2: darken($secondary, 10) !default;
$secondary-l1: lighten($secondary, 22.94) !default;
$secondary-l2: lighten($secondary, 32.94) !default;
$secondary-l3: lighten($secondary, 44.90) !default;

$info-d1: darken($info, 7.5) !default;
$info-d2: darken($info, 10) !default;
$info-l1: lighten($info, 22.94) !default;
$info-l2: lighten($info, 32.94) !default;

$success-d1: darken($success, 7.5) !default;
$success-d2: darken($success, 10) !default;
$success-l1: lighten($success, 22.94) !default;
$success-l2: lighten($success, 32.94) !default;

$warning-d1: darken($warning, 7.5) !default;
$warning-d2: darken($warning, 10) !default;
$warning-l1: lighten($warning, 22.94) !default;
$warning-l2: lighten($warning, 32.94) !default;

$danger-d1: darken($danger, 7.5) !default;
$danger-d2: darken($danger, 10) !default;
$danger-l1: lighten($danger, 22.94) !default;
$danger-l2: lighten($danger, 32.94) !default;

$light-d1: darken($light, 7.5) !default;
$light-d2: darken($light, 10) !default;
$light-l1: lighten($light, 22.94) !default;
$light-l2: lighten($light, 32.94) !default;

$dark-d1: darken($dark, 7.5) !default;
$dark-d2: darken($dark, 10) !default;
$dark-l1: lighten($dark, 22.94) !default;
$dark-l2: lighten($dark, 32.94) !default;

$enable-scaling-components: false !default;
$scaling-breakpoint-down: sm !default;

$rounded-border-radius: $border-radius !default;
$rounded-circle-border-radius: 5000px !default;
$rounded-0-border-radius: 0 !default;

// Cursors

$link-cursor: pointer !default;
$disabled-cursor: not-allowed !default;

// Containers

$container-form-lg: () !default;
$container-form-lg: map-deep-merge((
	breakpoint-up: lg,
	padding-bottom: 3rem,
	padding-top: 3rem,
	padding-bottom-mobile: 1rem,
	padding-top-mobile: 1rem
), $container-form-lg);

$container-view: () !default;
$container-view: map-deep-merge((
	padding-bottom: 1.5rem,
	padding-top: 1.5rem
), $container-view);

// Fonts

$moz-osx-font-smoothing: null !default;
$webkit-font-smoothing: null !default;

$font-import-url: null !default;

$font-family-serif: Georgia, "Times New Roman", Times, serif !default;

$font-size-base-mobile: $font-size-base !default;
$font-size-lg-mobile: $font-size-lg !default;

$font-weight-semi-bold: 500 !default;
$font-weight-bolder: 900 !default;

$h1-font-size-mobile: null !default;
$h2-font-size-mobile: null !default;
$h3-font-size-mobile: null !default;
$h4-font-size-mobile: null !default;
$h5-font-size-mobile: null !default;
$h6-font-size-mobile: null !default;

$body-moz-osx-font-smoothing: $moz-osx-font-smoothing !default;
$body-webkit-font-smoothing: $webkit-font-smoothing !default;
$body-text-align: inherit !default;