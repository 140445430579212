.component-link {
	@include clay-link($component-link);
}

.single-link {
	font-weight: $single-link-font-weight;
}

.link-primary {
	@include clay-link($link-primary);
}

.link-secondary {
	@include clay-link($link-secondary);
}

// Link Outline

button.link-outline {
	cursor: $btn-cursor;
}

.link-outline {
	align-items: center;
	background-color: transparent;
	border-color: transparent;

	@include border-radius($link-outline-border-radius);

	border-style: solid;
	border-width: $link-outline-border-width;
	display: inline-flex;
	font-size: $link-outline-font-size;
	font-weight: $link-outline-font-weight;
	justify-content: center;
	line-height: $link-outline-line-height;
	padding-bottom: $link-outline-padding-y;
	padding-left: $link-outline-padding-x;
	padding-right: $link-outline-padding-x;
	padding-top: $link-outline-padding-y;
	transition: $link-outline-transition;
	vertical-align: middle;

	&:hover {
		text-decoration: none;
	}

	.inline-item .lexicon-icon,
	.lexicon-icon {
		margin-top: 0;
	}
}

.link-outline-primary {
	@include clay-link($link-outline-primary);
}

.link-outline-secondary {
	@include clay-link($link-outline-secondary);
}

.link-outline-borderless {
	border-color: transparent;
}

%link-monospaced {
	align-items: center;
	display: inline-flex;
	height: $link-monospaced-size;
	justify-content: center;
	overflow: hidden;
	padding: 0;
	vertical-align: middle;
	width: $link-monospaced-size;

	.inline-item .lexicon-icon,
	.lexicon-icon {
		margin-top: 0;
	}
}

.link-monospaced {
	@extend %link-monospaced;
}

// Component Links

.component-title {
	@include clay-text-typography($component-title);

	a {
		@include clay-link($component-title-link);
	}
}

.component-subtitle {
	@include clay-text-typography($component-subtitle);

	a {
		@include clay-link($component-subtitle-link);
	}
}

.component-action {
	@include clay-link($component-action);

	@extend %link-monospaced;
}