// `clay-navbar-size` is deprecated as of v2.11.1
// Sizes Navbar Desktop and Mobile based on height, font-size, and padding
// @param $map - Sass map that contain Navbar properties to modify

@mixin clay-navbar-size($map) {
	$enabled: setter(map-get($map, enabled), true);
	$scaling-navbar: setter(map-get($map, scaling-navbar), false);
	$container-padding-x: map-get($map, container-padding-x);
	$container-padding-x-mobile: setter(map-get($map, container-padding-x-mobile), $container-padding-x);

	$height: setter(map-get($map, height), 3.5rem);
	$border-bottom-width: setter(map-get($map, border-bottom-width), 0);
	$border-left-width: setter(map-get($map, border-left-width), 0);
	$border-right-width: setter(map-get($map, border-right-width), 0);
	$border-top-width: setter(map-get($map, border-top-width), 0);
	$box-shadow: map-get($map, box-shadow);
	$font-size: setter(map-get($map, font-size), $font-size-base);
	$padding-x: setter(map-get($map, padding-x), $navbar-padding-x);
	$padding-y: setter(map-get($map, padding-y), $navbar-padding-y);
	$link-height: setter(map-get($map, link-height), $height - $border-bottom-width - $border-top-width - ($padding-y * 2));
	$link-margin-x: map-get($map, link-margin-x);
	$link-margin-y: setter(map-get($map, link-margin-y), (($height - $border-bottom-width - $border-top-width) - $link-height) / 2);
	$link-padding-x: setter(map-get($map, link-padding-x), $navbar-nav-link-padding-x);
	$link-padding-y: setter(map-get($map, link-padding-y), ($link-height - ($font-size * $line-height-base)) / 2);
	$btn-font-size: setter(map-get($map, btn-font-size), $font-size);
	$btn-monospaced-font-size: map-get($map, btn-monospaced-font-size);
	$btn-monospaced-size: map-get($map, btn-monospaced-size);
	$btn-margin-x: setter(map-get($map, btn-margin-x), $link-padding-x);
	$btn-margin-y: setter(map-get($map, btn-margin-y), ($height - $border-bottom-width - $border-top-width - ($padding-y * 2) - if($btn-monospaced-size, $btn-monospaced-size, $nav-item-monospaced-size)) / 2);
	$btn-padding-x: setter(map-get($map, btn-padding-x), $link-padding-x);
	$btn-padding-y: setter(map-get($map, btn-padding-y), $link-padding-y);
	$form-control-height: map-get($map, form-control-height);
	$brand-font-size: setter(map-get($map, brand-font-size), $navbar-brand-font-size);
	$brand-max-width: map-get($map, brand-max-width);
	$brand-margin-right: setter(map-get($map, brand-margin-right), $link-padding-x);
	$brand-padding-x: setter(map-get($map, brand-padding-x), $link-padding-x);
	$brand-padding-y: setter(map-get($map, brand-padding-y), (($height - $border-bottom-width - $border-top-width - ($padding-y * 2) - ($brand-font-size * $line-height-base)) / 2));
	$title-font-size: map-get($map, title-font-size);
	$title-font-weight: map-get($map, title-font-weight);
	$title-margin-bottom: map-get($map, title-margin-bottom);
	$title-text-transform: map-get($map, title-text-transform);
	$nav-item-dropdown-margin-top: map-get($map, nav-item-dropdown-margin-top);

	$height-mobile: setter(map-get($map, height-mobile), $height);
	$font-size-mobile: setter(map-get($map, font-size-mobile), $font-size);
	$link-height-mobile: setter(map-get($map, link-height-mobile), $height-mobile - $border-bottom-width - $border-top-width - ($padding-y * 2));
	$link-margin-x-mobile: setter(map-get($map, link-margin-x-mobile), $link-margin-x);
	$link-margin-y-mobile: setter(map-get($map, link-margin-y-mobile), (($height-mobile - $border-bottom-width - $border-top-width) - $link-height-mobile) / 2);
	$link-padding-x-mobile: setter(map-get($map, link-padding-x-mobile), $link-padding-x);
	$link-padding-y-mobile: setter(map-get($map, link-padding-y-mobile), ($link-height-mobile - ($font-size-mobile * $line-height-base)) / 2);
	$btn-font-size-mobile: setter(map-get($map, btn-font-size-mobile), $font-size-mobile);
	$btn-monospaced-font-size-mobile: setter(map-get($map, btn-monospaced-font-size-mobile), $btn-monospaced-font-size);
	$btn-monospaced-size-mobile: setter(map-get($map, btn-monospaced-size-mobile), $btn-monospaced-size);
	$btn-margin-x-mobile: setter(map-get($map, btn-margin-x-mobile), $link-padding-x-mobile);
	$btn-margin-y-mobile: setter(map-get($map, btn-margin-y-mobile), ($height-mobile - $border-bottom-width - $border-top-width - ($padding-y * 2) - if($btn-monospaced-size-mobile, $btn-monospaced-size-mobile, $nav-item-monospaced-size)) / 2);
	$btn-padding-x-mobile: setter(map-get($map, btn-padding-x-mobile), $link-padding-x-mobile);
	$btn-padding-y-mobile: setter(map-get($map, btn-padding-y-mobile), $link-padding-x-mobile);
	$form-control-height-mobile: map-get($map, form-control-height-mobile);
	$brand-font-size-mobile: setter(map-get($map, brand-font-size-mobile), $font-size-lg-mobile);
	$brand-margin-right-mobile: setter(map-get($map, brand-margin-right-mobile), 0);
	$brand-padding-x-mobile: setter(map-get($map, brand-padding-x-mobile), $link-padding-x-mobile);
	$brand-padding-y-mobile: setter(map-get($map, brand-padding-y-mobile), (($height-mobile - $border-bottom-width - $border-top-width - ($padding-y * 2) - ($brand-font-size-mobile * $line-height-base)) / 2));
	$collapse-dropdown-item-padding-x-mobile: map-get($map, collapse-dropdown-item-padding-x-mobile);
	$collapse-dropdown-item-padding-y-mobile: map-get($map, collapse-dropdown-item-padding-y-mobile);

	$toggler-font-size: setter(map-get($map, toggler-font-size), $navbar-toggler-font-size);
	$toggler-height: setter(map-get($map, toggler-height), $height-mobile * 0.66667);
	$toggler-margin-x: setter(map-get($map, toggler-margin-x), $link-padding-x-mobile);
	$toggler-margin-y: map-get($map, toggler-margin-y);
	$toggler-padding-x: setter(map-get($map, toggler-padding-x), $navbar-toggler-padding-x);
	$toggler-padding-y: setter(map-get($map, toggler-padding-y), $navbar-toggler-padding-y);

	$toggler-link-font-size: setter(map-get($map, toggler-link-font-size), $font-size-mobile);
	$toggler-link-height: setter(map-get($map, toggler-link-height), auto);
	$toggler-link-line-height: setter(map-get($map, toggler-link-line-height), $line-height-base);
	$toggler-link-margin-x: setter(map-get($map, toggler-link-margin-x), 0);
	$toggler-link-margin-y: map-get($map, toggler-link-margin-y);
	$toggler-link-padding-x: setter(map-get($map, toggler-link-padding-x), $link-padding-x-mobile);
	$toggler-link-padding-y: setter(map-get($map, toggler-link-padding-y), ($height-mobile - $border-bottom-width - $border-top-width - ($padding-y * 2) - ($toggler-link-font-size * $toggler-link-line-height)) / 2);

	$active-border-bottom-width: setter(map-get($map, active-border-bottom-width), 0.125rem);
	$active-border-offset-x: map-get($map, active-border-offset-x);
	$active-border-offset-bottom: setter(map-get($map, active-border-offset-bottom), -$border-bottom-width - $padding-y - $link-margin-y);
	$active-border-offset-top: map-get($map, active-border-offset-top);

	$active-border-offset-bottom-mobile: setter(map-get($map, active-border-offset-bottom-mobile), -$border-bottom-width - $padding-y - $link-margin-y-mobile);

	@if ($enabled) {
		@include border-radius($navbar-border-radius);

		border-width: $border-top-width $border-right-width $border-bottom-width $border-left-width;

		@include box-shadow($box-shadow);

		font-size: $font-size;
		padding: $padding-y $padding-x;

		.container,
		.container-fluid {
			padding-left: $container-padding-x-mobile;
			padding-right: $container-padding-x-mobile;
		}

		.navbar-toggler {
			font-size: $toggler-font-size;
			height: $toggler-height;
			margin-bottom: $toggler-margin-y;
			margin-left: $toggler-margin-x;
			margin-right: $toggler-margin-x;
			margin-top: $toggler-margin-y;
			padding-bottom: $toggler-padding-y;
			padding-left: $toggler-padding-x;
			padding-right: $toggler-padding-x;
			padding-top: $toggler-padding-y;
		}

		.navbar-toggler-link {
			height: $toggler-link-height;
			font-size: $toggler-link-font-size;
			line-height: $toggler-link-line-height;
			margin-bottom: $toggler-link-margin-y;
			margin-left: $toggler-link-margin-x;
			margin-right: $toggler-link-margin-x;
			margin-top: $toggler-link-margin-y;
			padding-bottom: $toggler-link-padding-y;
			padding-left: $toggler-link-padding-x;
			padding-right: $toggler-link-padding-x;
			padding-top: $toggler-link-padding-y;
		}

		.navbar-brand {
			font-size: $brand-font-size-mobile;
			margin-right: $brand-margin-right-mobile;
			max-width: $brand-max-width;
			padding-bottom: $brand-padding-y-mobile;
			padding-left: $brand-padding-x-mobile;
			padding-right: $brand-padding-x-mobile;
			padding-top: $brand-padding-y-mobile;
		}

		.navbar-title {
			font-size: $title-font-size;
			font-weight: $title-font-weight;
			margin-bottom: $title-margin-bottom;
			text-transform: $title-text-transform;
		}

		.navbar-nav {
			.nav-btn {
				font-size: $btn-font-size-mobile;
				height: $btn-monospaced-size-mobile;
				margin-bottom: $btn-margin-y-mobile;
				margin-left: $btn-margin-x-mobile;
				margin-right: $btn-margin-x-mobile;
				margin-top: $btn-margin-y-mobile;
				padding-bottom: $btn-padding-y-mobile;
				padding-left: $btn-padding-x-mobile;
				padding-right: $btn-padding-x-mobile;
				padding-top: $btn-padding-y-mobile;
				min-width: $btn-monospaced-size-mobile;
			}

			.nav-btn-monospaced {
				font-size: $btn-monospaced-font-size-mobile;
				padding: 0;
			}

			.nav-item {
				> .custom-control,
				> .form-check {
					margin-left: $btn-margin-x-mobile;
					margin-right: $btn-margin-x-mobile;
				}
			}

			.nav-link,
			.navbar-text {
				margin-bottom: $link-margin-y-mobile;
				margin-left: $link-margin-x-mobile;
				margin-right: $link-margin-x-mobile;
				margin-top: $link-margin-y-mobile;
				padding-bottom: $link-padding-y-mobile;
				padding-left: $link-padding-x-mobile;
				padding-right: $link-padding-x-mobile;
				padding-top: $link-padding-y-mobile;
			}

			.nav-link-monospaced {
				font-size: $btn-monospaced-font-size-mobile;
				margin-bottom: $btn-margin-y-mobile;
				margin-left: $btn-margin-x-mobile;
				margin-right: $btn-margin-x-mobile;
				margin-top: $btn-margin-y-mobile;
				padding: 0;
			}
		}

		.dropdown-menu {
			margin-top: $nav-item-dropdown-margin-top;
		}

		// Navbar Expand

		&.navbar-expand {
			@each $breakpoint in map-keys($grid-breakpoints) {
				$next: breakpoint-next($breakpoint, $grid-breakpoints);
				$infix: breakpoint-infix($next, $grid-breakpoints);

				&#{$infix} { // .navbar-expand, sm, md, lg, xl
					@if not ($infix == '') { // .navbar-expand-sm, md, lg, xl
						@include media-breakpoint-down($breakpoint) {
							&.navbar-collapse-absolute {
								.navbar-collapse {
									border-color: transparent;
									border-style: solid;
									border-width: 0 $border-right-width $border-bottom-width $border-left-width;

									@include box-shadow($box-shadow);

									left: -$border-left-width;
									margin-top: $border-bottom-width;
									padding-bottom: $padding-y;
									padding-left: $padding-x;
									padding-right: $padding-x;
									padding-top: $padding-y;
									right: -$border-right-width;
								}
							}

							.navbar-collapse {
								.navbar-nav {
									.dropdown-divider {
										margin-left: -$padding-x;
										margin-right: -$padding-x;
									}

									.dropdown-item {
										padding-bottom: $collapse-dropdown-item-padding-y-mobile;
										padding-left: $collapse-dropdown-item-padding-x-mobile;
										padding-right: $collapse-dropdown-item-padding-x-mobile;
										padding-top: $collapse-dropdown-item-padding-y-mobile;
									}
								}
							}

							.navbar-form {
								height: $height-mobile - $border-bottom-width - $border-top-width;
								padding-bottom: $link-padding-y-mobile;
								padding-left: $link-padding-x-mobile;
								padding-right: $link-padding-x-mobile;
								padding-top: $link-padding-y-mobile;

								.form-control {
									height: $form-control-height-mobile;
									padding-bottom: 0;
									padding-top: 0;
								}
							}
						}
					}

					@include media-breakpoint-up($next) {
						.container,
						.container-fluid {
							@if ($scaling-navbar) {
								padding-left: $container-padding-x;
								padding-right: $container-padding-x;
							}
						}

						.navbar-brand {
							@if ($scaling-navbar) {
								font-size: $brand-font-size;
								margin-right: $brand-margin-right;
								padding-bottom: $brand-padding-y;
								padding-left: $brand-padding-x;
								padding-right: $brand-padding-x;
								padding-top: $brand-padding-y;
							}
						}

						.navbar-form {
							@if ($scaling-navbar) {
								height: $height - $border-bottom-width - $border-top-width;
								padding-left: $link-padding-x;
								padding-right: $link-padding-x;

								> .container,
								> .container-fluid {
									padding-left: 0;
									padding-right: 0;
								}

								.form-control {
									height: $form-control-height;
								}
							}
						}

						.nav-btn {
							@if ($scaling-navbar) {
								font-size: $btn-font-size;
								height: $btn-monospaced-size;
								margin-bottom: $btn-margin-y;
								margin-left: $btn-margin-x;
								margin-right: $btn-margin-x;
								margin-top: $btn-margin-y;
								padding-bottom: $btn-padding-y;
								padding-left: $btn-padding-x;
								padding-right: $btn-padding-x;
								padding-top: $btn-padding-y;
								min-width: $btn-monospaced-size;
							}
						}

						.nav-btn-monospaced {
							@if ($scaling-navbar) {
								font-size: $btn-monospaced-font-size;
								padding: 0;
							}
						}

						.nav-item {
							> .custom-control,
							> .form-check {
								@if ($scaling-navbar) {
									margin-left: $btn-margin-x;
									margin-right: $btn-margin-x;
								}
							}
						}

						.nav-link,
						.navbar-text {
							@if ($scaling-navbar) {
								margin-bottom: $link-margin-y;
								margin-left: $link-margin-x;
								margin-right: $link-margin-x;
								margin-top: $link-margin-y;
								padding-bottom: $link-padding-y;
								padding-left: $link-padding-x;
								padding-right: $link-padding-x;
								padding-top: $link-padding-y;
							}
						}

						.nav-link-monospaced {
							@if ($scaling-navbar) {
								font-size: $btn-monospaced-font-size;
								margin-bottom: $btn-margin-y;
								margin-left: $btn-margin-x;
								margin-right: $btn-margin-x;
								margin-top: $btn-margin-y;
								padding: 0;
							}
						}
					}
				}
			}
		}

		// Navbar Overlay Styles for `.navbar-overlay-xs-down`,
		// `.navbar-overlay-sm-down`, `.navbar-overlay-md-down`,
		// `.navbar-overlay-lg-down`, `.navbar-overlay-up`

		@each $breakpoint in map-keys($grid-breakpoints) {
			$index: index(map-keys($grid-breakpoints), $breakpoint);
			$length: length(map-keys($grid-breakpoints));
			$infix: '.navbar-overlay-#{$breakpoint}-down';

			@if ($index == $length) {
				$infix: '.navbar-overlay-up';
			}

			#{$infix} {
				@include media-breakpoint-down($breakpoint) {
					@include border-radius($navbar-border-radius);

					padding-bottom: $padding-y;
					padding-left: $padding-x;
					padding-right: $padding-x;
					padding-top: $padding-y;
				}
			}
		}

		// Navbar Underline

		&.navbar-underline {
			.navbar-toggler-link {
				&:after {
					bottom: $active-border-offset-bottom-mobile;
					height: $active-border-bottom-width;
					left: $active-border-offset-x;
					right: $active-border-offset-x;
					top: $active-border-offset-top;
				}
			}
		}

		&.navbar-underline.navbar-expand {
			@each $breakpoint in map-keys($grid-breakpoints) {
				$next: breakpoint-next($breakpoint, $grid-breakpoints);
				$infix: breakpoint-infix($next, $grid-breakpoints);

				&#{$infix} { // .navbar-expand, sm, md, lg, xl
					@include media-breakpoint-up($next) {
						.navbar-nav .nav-link {
							&.active:after {
								bottom: $active-border-offset-bottom;
								height: $active-border-bottom-width;
								left: $active-border-offset-x;
								right: $active-border-offset-x;
								top: $active-border-offset-top;
							}
						}
					}
				}
			}
		}
	}
}

// `clay-navbar-variant` is deprecated as of v2.11.1
// Creates a Navbar color variant
// @param $map - Sass map that contain Navbar Variant properties to modify

@mixin clay-navbar-variant($map) {
	$enabled: setter(map-get($map, enabled), true);
	$bg: map-get($map, bg);
	$border-color: setter(map-get($map, border-color), transparent);
	$border-style: setter(map-get($map, border-style), solid);
	$color: map-get($map, color);
	$link-border-radius: map-get($map, link-border-radius);
	$link-bg: map-get($map, link-bg);
	$link-color: map-get($map, link-color);
	$link-font-weight: map-get($map, link-font-weight);
	$link-outline: map-get($map, link-outline);
	$link-transition: map-get($map, link-transition);
	$link-hover-bg: map-get($map, link-hover-bg);
	$link-hover-color: map-get($map, link-hover-color);
	$link-focus-bg: map-get($map, link-focus-bg);
	$link-focus-box-shadow: map-get($map, link-focus-box-shadow);
	$link-focus-color: map-get($map, link-focus-color);
	$link-active-bg: map-get($map, link-active-bg);
	$link-active-color: map-get($map, link-active-color);
	$link-disabled-bg: map-get($map, link-disabled-bg);
	$link-disabled-box-shadow: map-get($map, link-disabled-box-shadow);
	$link-disabled-color: map-get($map, link-disabled-color);
	$link-disabled-opacity: map-get($map, link-disabled-opacity);
	$link-disabled-pointer-events: map-get($map, link-disabled-pointer-events);
	$btn-font-weight: setter(map-get($map, btn-font-weight), $link-font-weight);
	$brand-color: map-get($map, brand-color);
	$brand-hover-color: map-get($map, brand-hover-color);
	$brand-bg: map-get($map, brand-bg);
	$brand-hover-bg: map-get($map, brand-hover-bg);
	$toggler-color: setter(map-get($map, toggler-color), $link-color);
	$toggler-font-weight: setter(map-get($map, toggler-font-weight), $link-font-weight);
	$underline-active-bg: map-get($map, underline-active-bg);

	@if ($enabled) {
		background-color: $bg;
		border-color: $border-color;
		border-style: $border-style;
		color: $color;

		.nav-link,
		.navbar-nav .btn-unstyled {
			background-color: $link-bg;

			@include border-radius($link-border-radius);

			color: $link-color;
			font-weight: $link-font-weight;
			outline: $link-outline;
			transition: $link-transition;

			&:hover {
				background-color: $link-hover-bg;
				color: $link-hover-color;
			}

			&:focus {
				background-color: $link-focus-bg;
				box-shadow: $link-focus-box-shadow;
				color: $link-focus-color;
			}

			&.active,
			&[aria-expanded="true"] {
				background-color: $link-active-bg;
				color: $link-active-color;
			}

			&.disabled,
			&:disabled {
				background-color: $link-disabled-bg;
				box-shadow: $link-disabled-box-shadow;
				color: $link-disabled-color;
				opacity: $link-disabled-opacity;
				pointer-events: $link-disabled-pointer-events;
			}
		}

		.nav-btn {
			font-weight: $btn-font-weight;
		}

		.navbar-brand {
			background-color: $brand-bg;

			@include border-radius($link-border-radius);

			color: $brand-color;
			outline: $link-outline;
			transition: $link-transition;

			&:hover {
				background-color: $brand-bg;
				color: $brand-hover-color;
			}

			&:focus {
				box-shadow: $link-focus-box-shadow;
				color: $link-focus-color;
			}
		}

		.navbar-toggler {
			color: $toggler-color;
			font-weight: $toggler-font-weight;
			outline: $link-outline;
			transition: $link-transition;

			&:focus {
				box-shadow: $link-focus-box-shadow;
				color: $link-focus-color;
			}
		}

		.navbar-toggler-link {
			&[aria-expanded="true"] {
				color: $link-active-color;
			}
		}

		.navbar-overlay {
			background-color: $bg;
		}

		&.navbar-expand {
			@each $breakpoint in map-keys($grid-breakpoints) {
				$next: breakpoint-next($breakpoint, $grid-breakpoints);
				$infix: breakpoint-infix($next, $grid-breakpoints);

				&#{$infix} { // .navbar-expand, sm, md, lg, xl
					@if not ($infix == '') { // .navbar-expand-sm, md, lg, xl
						@include media-breakpoint-down($breakpoint) {
							&.navbar-collapse-absolute .navbar-collapse {
								background-color: $bg;
								border-color: $border-color;
							}

							.navbar-collapse .navbar-nav {
								.dropdown-header,
								.dropdown-item {
									color: $link-color;
									font-weight: $link-font-weight;
								}

								.dropdown-divider {
									border-color: $link-color;
								}

								.dropdown-item {
									&:hover {
										color: $link-hover-color;
									}

									&.active {
										color: $link-active-color;
									}

									&.disabled {
										color: $link-disabled-color;
									}
								}
							}
						}
					}

					@include media-breakpoint-up($next) {
						&.navbar-underline .navbar-nav .nav-link.active:after {
							background-color: $underline-active-bg;
						}
					}
				}
			}
		}

		@content;
	}
}