// Menubar Vertical MD

$menubar-vertical-expand-md: () !default;
$menubar-vertical-expand-md: map-deep-merge((
	margin-bottom-mobile: 1.5rem,
	margin-left-mobile: -($grid-gutter-width / 2),
	margin-right-mobile: -($grid-gutter-width / 2),
	collapse-z-index-mobile: $zindex-menubar-vertical-expand-md-collapse-mobile,
), $menubar-vertical-expand-md);

$menubar-vertical-transparent-md: () !default;
$menubar-vertical-transparent-md: map-deep-merge((
	link-color: $gray-600,
	link-hover-color: darken($gray-600, 15),
	link-active-color: $gray-900,
	bg-mobile: $gray-100,
), $menubar-vertical-transparent-md);

// Menubar Vertical LG

$menubar-vertical-expand-lg: () !default;
$menubar-vertical-expand-lg: map-deep-merge((
	breakpoint-up: lg,
	margin-bottom-mobile: 1.5rem,
	margin-left-mobile: -($grid-gutter-width / 2),
	margin-right-mobile: -($grid-gutter-width / 2),
	collapse-z-index-mobile: $zindex-menubar-vertical-expand-lg-collapse-mobile,
), $menubar-vertical-expand-lg);

$menubar-vertical-transparent-lg: () !default;
$menubar-vertical-transparent-lg: map-deep-merge((
	breakpoint-up: lg,
	link-color: $gray-600,
	link-hover-color: darken($gray-600, 15),
	link-active-color: $gray-900,
	bg-mobile: $gray-100,
), $menubar-vertical-transparent-lg);