// Form Feedback

.invalid-feedback,
.valid-feedback {
	font-size: $form-feedback-font-size;
	font-weight: $form-feedback-font-weight;
}

.form-feedback-group {
	width: 100%;
	word-wrap: break-word;
}

.form-feedback-item {
	font-size: $form-feedback-font-size;
	font-weight: $form-feedback-font-weight;
	margin-top: $form-feedback-margin-top;
	word-wrap: break-word;
}

.form-feedback-indicator {
	margin-left: $form-feedback-indicator-margin-x;
	margin-right: $form-feedback-indicator-margin-x;

	&:first-child {
		margin-left: 0;
	}
}

.form-text {
	color: $form-text-color;
	font-size: $form-text-font-size;
	font-weight: $form-text-font-weight;
	word-wrap: break-word;
}

// HTML 5 Form Validator

.was-validated .form-control:valid,
.was-validated .form-control.is-valid {
	background-color: $input-success-bg;
	border-color: $input-success-border-color;
	box-shadow: $input-success-box-shadow;
	color: $input-success-color;

	&:focus {
		background-color: $input-success-focus-bg;
		border-color: $input-success-focus-border-color;
		box-shadow: $input-success-focus-box-shadow;
		color: $input-success-focus-color;
	}
}

.was-validated .form-control:invalid,
.was-validated .form-control.is-invalid {
	background-color: $input-danger-bg;
	border-color: $input-danger-border-color;
	box-shadow: $input-danger-box-shadow;
	color: $input-danger-color;

	&:focus {
		background-color: $input-danger-focus-bg;
		border-color: $input-danger-focus-border-color;
		box-shadow: $input-danger-focus-box-shadow;
		color: $input-danger-focus-color;
	}
}

// Non HTML5 Form Validator

.has-error {
	label {
		color: $input-danger-label-color;
	}

	.custom-control-label,
	.form-check-label {
		color: $input-danger-checkbox-label-color;
	}

	.form-control {
		background-color: $input-danger-bg;
		border-color: $input-danger-border-color;
		box-shadow: $input-danger-box-shadow;
		color: $input-danger-color;

		&:focus {
			background-color: $input-danger-focus-bg;
			border-color: $input-danger-focus-border-color;
			box-shadow: $input-danger-focus-box-shadow;
			color: $input-danger-focus-color;
		}
	}

	.form-control[readonly] {
		@include clay-button-variant($input-danger-readonly);
	}

	.form-feedback-item {
		color: $form-feedback-invalid-color;
	}

	select.form-control:not([multiple]):not([size]) {
		background-image: $input-danger-select-icon;
	}

	.input-group-item {
		&.focus {
			box-shadow: setter($input-danger-focus-box-shadow, $input-danger-box-shadow);

			.form-control,
			.input-group-inset-item {
				background-color: setter($input-danger-focus-bg, $input-danger-bg);
				border-color: setter($input-danger-focus-border-color, $input-danger-border-color);
			}
		}

		.input-group-inset:focus {
			box-shadow: none;

			~ .input-group-inset-item {
				background-color: setter($input-danger-focus-bg, $input-danger-bg);
				border-color: setter($input-danger-focus-border-color, $input-danger-border-color);
			}
		}
	}

	.input-group-inset-item {
		background-color: $input-danger-bg;
		border-color: $input-danger-border-color;
		box-shadow: $input-danger-box-shadow;
	}
}

.has-warning {
	label {
		color: $input-warning-label-color;
	}

	.custom-control-label,
	.form-check-label {
		color: $input-warning-checkbox-label-color;
	}

	.form-control {
		background-color: $input-warning-bg;
		border-color: $input-warning-border-color;
		box-shadow: $input-warning-box-shadow;
		color: $input-warning-color;

		&:focus {
			background-color: $input-warning-focus-bg;
			border-color: $input-warning-focus-border-color;
			box-shadow: $input-warning-focus-box-shadow;
			color: $input-warning-focus-color;
		}
	}

	.form-control[readonly] {
		@include clay-button-variant($input-warning-readonly);
	}

	.form-feedback-item {
		color: $form-feedback-warning-color;
	}

	select.form-control:not([multiple]):not([size]) {
		background-image: $input-warning-select-icon;
	}

	.input-group-item {
		&.focus {
			box-shadow: setter($input-warning-focus-box-shadow, $input-warning-box-shadow);

			.form-control,
			.input-group-inset-item {
				background-color: setter($input-warning-focus-bg, $input-warning-bg);
				border-color: setter($input-warning-focus-border-color, $input-warning-border-color);
			}
		}

		.input-group-inset:focus {
			box-shadow: none;

			~ .input-group-inset-item {
				background-color: setter($input-warning-focus-bg, $input-warning-bg);
				border-color: setter($input-warning-focus-border-color, $input-warning-border-color);
			}
		}
	}

	.input-group-inset-item {
		background-color: $input-warning-bg;
		border-color: $input-warning-border-color;
		box-shadow: $input-warning-box-shadow;
	}
}

.has-success {
	label {
		color: $input-success-label-color;
	}

	.custom-control-label,
	.form-check-label {
		color: $input-success-checkbox-label-color;
	}

	.form-control {
		background-color: $input-success-bg;
		border-color: $input-success-border-color;
		box-shadow: $input-success-box-shadow;
		color: $input-success-color;

		&:focus {
			background-color: $input-success-focus-bg;
			border-color: $input-success-focus-border-color;
			box-shadow: $input-success-focus-box-shadow;
			color: $input-success-focus-color;
		}
	}

	.form-control[readonly] {
		@include clay-button-variant($input-success-readonly);
	}

	.form-feedback-item {
		color: $form-feedback-valid-color;
	}

	select.form-control:not([multiple]):not([size]) {
		background-image: $input-success-select-icon;
	}

	.input-group-item {
		&.focus {
			box-shadow: setter($input-success-focus-box-shadow, $input-success-box-shadow);

			.form-control,
			.input-group-inset-item {
				background-color: setter($input-success-focus-bg, $input-success-bg);
				border-color: setter($input-success-focus-border-color, $input-success-border-color);
			}
		}

		.input-group-inset:focus {
			box-shadow: none;

			~ .input-group-inset-item {
				background-color: setter($input-success-focus-bg, $input-success-bg);
				border-color: setter($input-success-focus-border-color, $input-success-border-color);
			}
		}
	}

	.input-group-inset-item {
		background-color: $input-success-bg;
		border-color: $input-success-border-color;
		box-shadow: $input-success-box-shadow;
	}
}