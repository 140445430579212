$nav-font-size: null !default;

$nav-link-disabled-cursor: $disabled-cursor !default;

$nav-link: () !default;
$nav-link: map-deep-merge((
	padding: $nav-link-padding-y $nav-link-padding-x,
	position: relative,
	focus-z-index: 1,
	disabled-color: $nav-link-disabled-color,
	disabled-cursor: $nav-link-disabled-cursor,
	disabled-pointer-events: auto,
	disabled-active-pointer-events: none,
), $nav-link);

$nav-link-btn-unstyled: () !default;
$nav-link-btn-unstyled: map-deep-merge((
	width: 100%,
	disabled-opacity: 1,
), $nav-link-btn-unstyled);

$nav-item-monospaced-size: 2rem !default; // 32px

$nav-btn-margin-x: 0.25rem !default; // 4px
$nav-btn-margin-y: (($line-height-base * $font-size-base) + ($nav-link-padding-y * 2) - $nav-item-monospaced-size) / 2 !default;
$nav-btn-padding-x: $btn-padding-x-sm !default;
$nav-btn-padding-y: 0 !default;

$nav-form-padding-bottom: 0 !default;
$nav-form-padding-left: $nav-link-padding-x !default;
$nav-form-padding-right: $nav-link-padding-x !default;
$nav-form-padding-top: 0 !default;

// Nav Stacked

$nav-stacked-nav-form-padding-bottom: $nav-link-padding-y !default;
$nav-stacked-nav-form-padding-left: 0.5rem !default;
$nav-stacked-nav-form-padding-right: 0.5rem !default;
$nav-stacked-nav-form-padding-top: $nav-link-padding-y !default;

// Nav Nested

$nav-nested-margins-spacer-x: $nav-link-padding-x !default;
$nav-nested-spacer-x: $nav-link-padding-x !default;

// Nav Tabs

$nav-tabs-font-size: null !default;

// Nav Tabs Link

$nav-tabs-link-color: null !default;
$nav-tabs-link-padding-x: null !default;
$nav-tabs-link-padding-y: null !default;

$nav-tabs-link: () !default;
$nav-tabs-link: map-deep-merge((
	border-radius: $nav-tabs-border-radius $nav-tabs-border-radius 0 0,
	color: $nav-tabs-link-color,
	padding-bottom: $nav-tabs-link-padding-y,
	padding-left: $nav-tabs-link-padding-x,
	padding-right: $nav-tabs-link-padding-x,
	padding-top: $nav-tabs-link-padding-y,
	white-space: nowrap,
), $nav-tabs-link);

// Nav Tabs Link Show

$nav-tabs-link-show-color: null !default;
$nav-tabs-link-show-bg: null !default;
$nav-tabs-link-show-border-color: null !default;

$nav-tabs-link-show: () !default;
$nav-tabs-link-show: map-deep-merge((
	bg: $nav-tabs-link-show-bg,
	border-color: $nav-tabs-link-show-border-color,
	color: $nav-tabs-link-show-color,
), $nav-tabs-link-show);

// Nav Tabs Tab Pane

$nav-tabs-tab-pane-bg: null !default;
$nav-tabs-tab-pane-border-radius: null !default;
$nav-tabs-tab-pane-padding: null !default;

// Nav Underline

$nav-underline-font-size: null !default;

$nav-underline-link-highlight-palette: null !default;

$nav-underline-link-color: null !default;
$nav-underline-link-padding-x: null !default;
$nav-underline-link-padding-y: null !default;

$nav-underline-link-hover-color: null !default;

$nav-underline-link-active-color: null !default;

$nav-underline-link-disabled-color: null !default;

$nav-underline-link: () !default;
$nav-underline-link: map-deep-merge((
	color: $nav-underline-link-color,
	padding-bottom: $nav-underline-link-padding-y,
	padding-left: $nav-underline-link-padding-x,
	padding-right: $nav-underline-link-padding-x,
	padding-top: $nav-underline-link-padding-y,
	hover-color: $nav-underline-link-hover-color,
	focus-color: $nav-underline-link-hover-color,
	active-class-color: $nav-underline-link-active-color,
	disabled-color: $nav-underline-link-disabled-color,
), $nav-underline-link);

$nav-underline-link-show: () !default;
$nav-underline-link-show: map-deep-merge((
	color: $nav-underline-link-active-color,
), $nav-underline-link-show);

// Nav Underline Link Highlight

$nav-underline-link-highlight-content: null !default;
$nav-underline-link-highlight-height: null !default;
$nav-underline-link-highlight-bottom: 0 !default;
$nav-underline-link-highlight-left: $nav-link-padding-x / 2 !default;
$nav-underline-link-highlight-right: $nav-link-padding-x / 2 !default;
$nav-underline-link-highlight-top: null !default;

$nav-underline-link-active-highlight: $component-active-bg !default;
$nav-underline-link-active-content: '' !default;
$nav-underline-link-active-highlight-height: 0.1875rem !default;

$nav-underline-link-disabled-highlight: null !default;