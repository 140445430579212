////
/// @group sheet
////

/// A mixin to force `.btn` or `.btn-group` into block level elements at a max-width breakpoint.
/// @param {Map} $map - A map of `key: value` pairs. The keys and value types are listed below:
/// @example
/// enabled: {Bool}, // Set to false to prevent mixin styles from being output. Default: true
/// breakpoint-down: {String, Null}, // The Bootstrap 4 Breakpoint {xs | sm | md | lg | xl}
/// btn-margin-bottom-mobile: {Number | String | Null},
/// @todo
/// - Add @example
/// - Add @link to documentation

@mixin sheet-footer-btn-block($map) {
	$enabled: setter(map-get($map, enabled), true);

	$breakpoint-down: map-get($map, breakpoint-down);

	$btn-margin-bottom-mobile: map-get($map, btn-margin-bottom-mobile);

	@if ($enabled) {
		@include media-breakpoint-down($breakpoint-down) {
			display: block;

			.btn {
				display: block;
				margin-bottom: $btn-margin-bottom-mobile;
				width: 100%;
			}

			.btn-group {
				display: block;
			}

			.btn-group-item {
				display: block;
				margin-right: 0;
			}
		}
	}
}