////
/// @group sidebar
////

/// A mixin to create Sidebar color variants (e.g., `<div class="sidebar my-custom-sidebar-variant"></div>`).
/// @param {Map} $map - A map of `key: value` pairs. The keys and value types are listed below:
/// @example
/// bg: {Color | String | Null},
/// border-color: {Color | String | List | Null},
/// border-bottom-width: {Number | String}, // Default: 0
/// border-left-width: {Number | String}, // Default: 0
/// border-right-width: {Number | String}, // Default: 0
/// border-top-width: {Number | String}, // Default: 0
/// box-shadow: {String | List | Null},
/// color: {Color | String | Null},
/// panel-bg: {Color | String | Null},
/// component-header-title: {Map | Null}, // Pass parameters to `clay-text-typography` mixin
/// component-header-subtitle: {Map | Null}, // Pass parameters to `clay-text-typography` mixin
/// component-link: {Map | Null}, // Pass parameters to `clay-text-typography` mixin
/// dt: {Map | Null}, // Pass parameters to `clay-text-typography` mixin
/// dd: {Map | Null}, // Pass parameters to `clay-text-typography` mixin
/// sidebar-list-group-item-bg: {Color | String | Null},
/// sidebar-list-group-item-border-color: {Color | String | List | Null},
/// sidebar-list-group-title: {Map | Null}, // Pass parameters to `clay-text-typography` mixin
/// sidebar-list-group-subtitle: {Map | Null}, // Pass parameters to `clay-text-typography` mixin
/// sidebar-list-group-text: {Map | Null}, // Pass parameters to `clay-text-typography` mixin
/// sidebar-list-group-subtext: {Map | Null}, // Pass parameters to `clay-text-typography` mixin
/// panel-unstyled: {Map | Null}, // Pass parameters to `clay-panel-variant` mixin
/// @todo
/// - Add @example
/// - Add @link to documentation

@mixin clay-sidebar-variant($map) {
	$bg: map-get($map, bg);
	$border-color: map-get($map, border-color);
	$border-bottom-width: setter(map-get($map, border-bottom-width), 0);
	$border-left-width: setter(map-get($map, border-left-width), 0);
	$border-right-width: setter(map-get($map, border-right-width), 0);
	$border-top-width: setter(map-get($map, border-top-width), 0);
	$box-shadow: map-get($map, box-shadow);
	$color: map-get($map, color);
	$panel-bg: map-get($map, panel-bg);

	$component-header-title: setter(map-get($map, component-header-title), ());
	$component-header-subtitle: setter(map-get($map, component-header-subtitle), ());
	$component-link: setter(map-get($map, component-link), ());

	$dt: setter(map-get($map, dt), ());
	$dd: setter(map-get($map, dd), ());

	$sidebar-list-group-item-bg: map-get($map, sidebar-list-group-item-bg);
	$sidebar-list-group-item-border-color: map-get($map, sidebar-list-group-item-border-color);
	$sidebar-list-group-title: setter(map-get($map, sidebar-list-group-title), ());
	$sidebar-list-group-subtitle: setter(map-get($map, sidebar-list-group-subtitle), ());
	$sidebar-list-group-text: setter(map-get($map, sidebar-list-group-text), ());
	$sidebar-list-group-subtext: setter(map-get($map, sidebar-list-group-subtext), ());

	$panel-unstyled: setter(map-get($map, panel-unstyled), ());

	background-color: $bg;
	border-color: $border-color;
	border-style: solid;
	border-width: $border-top-width $border-right-width $border-bottom-width $border-left-width;

	@include box-shadow($box-shadow);

	color: $color;

	.component-link {
		@include clay-text-typography($component-link);
	}

	.sidebar-header {
		.component-title {
			@include clay-text-typography($component-header-title);
		}

		.component-subtitle {
			@include clay-text-typography($component-header-subtitle);
		}
	}

	.sidebar-list-group {
		.list-group-item {
			background-color: $sidebar-list-group-item-bg;
			border-color: $sidebar-list-group-item-border-color;
		}

		.list-group-title {
			@include clay-text-typography($sidebar-list-group-title);
		}

		.list-group-subtitle {
			@include clay-text-typography($sidebar-list-group-subtitle);
		}

		.list-group-text {
			@include clay-text-typography($sidebar-list-group-text);
		}

		.list-group-subtext {
			@include clay-text-typography($sidebar-list-group-subtext);
		}
	}

	.sidebar-panel {
		background-color: $panel-bg;
	}

	.sidebar-dt {
		@include clay-text-typography($dt);
	}

	.sidebar-dd {
		@include clay-text-typography($dd);
	}

	.panel-unstyled {
		@include clay-panel-variant($panel-unstyled);
	}
}