$sticker-font-size: 1rem !default; // 16px
$sticker-font-weight: $font-weight-bold !default;

// Sticker Sizes

$sticker-sm: () !default;
$sticker-sm: map-deep-merge((
	font-size: 0.75rem, // 12px
), $sticker-sm);

$sticker-lg: () !default;
$sticker-lg: map-deep-merge((
	font-size: 1.25rem, // 20px
), $sticker-lg);

$sticker-xl: () !default;
$sticker-xl: map-deep-merge((
	font-size: 1.5rem, // 25px
), $sticker-xl);

// Sticker Positions

$sticker-inside-offset: 1rem !default; // 16px

// Sticker User Icon

$sticker-user-icon: () !default;
$sticker-user-icon: map-deep-merge((
	box-shadow: 0 0 0 1px $gray-300,
), $sticker-user-icon);

// Sticker Variants

$sticker-primary-bg: $white !default;
$sticker-primary-color: $primary !default;

$sticker-secondary-bg: $white !default;
$sticker-secondary-color: $secondary !default;

$sticker-info-bg: $white !default;
$sticker-info-color: $info !default;

$sticker-success-bg: $white !default;
$sticker-success-color: $success !default;

$sticker-warning-bg: $white !default;
$sticker-warning-color: $warning !default;

$sticker-danger-bg: $white !default;
$sticker-danger-color: $danger !default;

$sticker-light-bg: $white !default;
$sticker-light-color: $dark !default;

$sticker-dark-bg: $dark !default;
$sticker-dark-color: $white !default;