// Date Picker Nav

$date-picker-nav-btn: () !default;
$date-picker-nav-btn: map-deep-merge((
	hover-color: $gray-900,
	focus-bg: $gray-200,
	focus-color: $gray-900,
	active-bg: $gray-200,
	disabled-color: $gray-500,
	disabled-opacity: null,
), $date-picker-nav-btn);

$date-picker-nav-select: () !default;
$date-picker-nav-select: map-deep-merge((
	bg: transparent,
	border-color: transparent,
	color: $secondary,
	font-size: $font-size-sm,
	font-weight: $font-weight-semi-bold,
	height: $input-height-sm,
	padding-bottom: 0,
	padding-left: 0.5rem,
	padding-top: 0,
	hover-bg: $gray-200,
	hover-color: $gray-900,
	focus-bg: $gray-200,
	focus-color: $gray-900,
	disabled-bg: transparent,
	disabled-color: $input-disabled-color,
), $date-picker-nav-select);

// Date Picker Calendar Item

$date-picker-date: () !default;
$date-picker-date: map-deep-merge((
	hover-color: $gray-900,
	focus-bg: $gray-200,
	focus-color: $gray-900,
	disabled-color: $gray-500,
	disabled-opacity: null,
), $date-picker-date);

$date-picker-previous-month-date: () !default;
$date-picker-previous-month-date: map-deep-merge((
	color: $gray-500,
	opacity: null,
	focus-opacity: null,
	active-opacity: null,
), $date-picker-previous-month-date);

$date-picker-next-month-date: () !default;
$date-picker-next-month-date: map-deep-merge((
	color: $gray-500,
	opacity: null,
	focus-opacity: null,
	active-opacity: null,
), $date-picker-next-month-date);