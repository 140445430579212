$navigation-bar-size: () !default;
$navigation-bar-size: map-deep-merge((
	scaling-navbar: false,
	border-bottom-width: 0.0625rem,
	font-size: $navbar-font-size,
	height: 3rem,
	padding-x: 0,
	padding-y: 0,
	link-padding-x: 1rem,
	collapse-dropdown-item-padding-x-mobile: 1rem,
	collapse-dropdown-item-padding-y-mobile: 0.71875rem,
	nav-item-dropdown-margin-top: 0,
	active-border-bottom-width: 0.25rem
), $navigation-bar-size);

$navigation-bar-base: () !default;

$navigation-bar-light: () !default;
$navigation-bar-light: map-deep-merge((
	bg: $light,
	link-color: $navbar-light-color,
	link-hover-color: $navbar-light-hover-color,
	link-active-color: $navbar-light-active-color,
	link-disabled-color: $navbar-light-disabled-color,
	link-disabled-opacity: 1
), $navigation-bar-light);

$navigation-bar-secondary: () !default;
$navigation-bar-secondary: map-deep-merge((
	bg: $secondary,
	color: $white,
	link-color: rgba(255, 255, 255, 0.65),
	link-hover-color: rgba(255, 255, 255, 0.9),
	link-active-color: rgba(255, 255, 255, 0.9),
	link-disabled-color: rgba(255, 255, 255, 0.25),
	link-disabled-opacity: 1,
	brand-color: rgba(255, 255, 255, 0.9),
	brand-hover-color: rgba(255, 255, 255, 0.9)
), $navigation-bar-secondary);