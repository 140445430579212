.multi-step-nav {
	align-items: flex-end;
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin-bottom: $multi-step-nav-margin-bottom;
	padding: $multi-step-nav-padding-y $multi-step-nav-padding-x;
	position: relative;
}

.multi-step-indicator-label-bottom {
	.multi-step-item {
		padding-bottom: $multi-step-indicator-label-font-size * $line-height-base;
	}

	.multi-step-indicator-label {
		top: $multi-step-icon-size;
	}
}

.multi-step-indicator-label-top {
	.multi-step-item {
		padding-top: $multi-step-indicator-label-font-size * $line-height-base;
	}

	.multi-step-indicator-label {
		bottom: $multi-step-icon-size + $multi-step-divider-height;
	}
}

.multi-step-title-center {
	.multi-step-title {
		margin-left: $multi-step-icon-size / 2;
		max-width: $multi-step-title-max-width;
		overflow: hidden;
		position: relative;
		text-align: center;
		text-overflow: ellipsis;
		transform: translateX(-50%);
		white-space: nowrap;
		width: auto;
	}
}

.multi-step-item {
	margin-bottom: $multi-step-item-margin-bottom;
	position: relative;

	&.active {
		.multi-step-icon {
			background-color: $multi-step-icon-active-bg;
			background-image: $multi-step-icon-active-bg-image;
			background-position: $multi-step-icon-active-bg-position;
			background-repeat: no-repeat;
			background-size: $multi-step-icon-active-bg-size;
			color: $multi-step-icon-active-color;

			@if ($multi-step-icon-active-bg-image) {
				&[data-multi-step-icon] {
					&::before {
						// https://issues.liferay.com/browse/LPS-147457

						content: none;
					}
				}
			}
		}

		.multi-step-divider {
			background-color: $multi-step-divider-active-bg;
		}
	}

	&.active.complete {
		.multi-step-icon {
			background-color: $multi-step-icon-active-bg;
			color: $multi-step-icon-active-color;
		}
	}

	&.complete {
		.dropdown .multi-step-icon {
			&, &:active {
				background-image: none;
			}
		}

		.multi-step-icon {
			background-color: $multi-step-icon-complete-bg;
			color: $multi-step-icon-complete-color;

			@if ($multi-step-icon-complete-bg-image) {
				background-image: $multi-step-icon-complete-bg-image;
				background-position: $multi-step-icon-complete-bg-position;
				background-repeat: no-repeat;
				background-size: $multi-step-icon-complete-bg-size;

				&[data-multi-step-icon] {
					&::before {
						// https://issues.liferay.com/browse/LPS-147457

						content: none;
					}
				}

				// BS4 resets background-image in buttons https://github.com/twbs/bootstrap/issues/23353
				&:active {
					background-image: $multi-step-icon-complete-bg-image;
				}
			}
		}

		.multi-step-divider {
			background-color: $multi-step-divider-complete-bg;
		}
	}

	&.disabled {
		.multi-step-title {
			color: $multi-step-title-disabled-color;
		}

		.multi-step-icon {
			background-color: $multi-step-icon-disabled-bg;
			background-image: $multi-step-icon-disabled-bg-image;
			background-position: $multi-step-icon-disabled-bg-position;
			background-repeat: no-repeat;
			background-size: $multi-step-icon-disabled-bg-size;
			color: $multi-step-icon-disabled-color;
			cursor: $multi-step-icon-disabled-cursor;
			opacity: $multi-step-icon-disabled-opacity;

			@if ($multi-step-icon-disabled-bg-image) {
				&[data-multi-step-icon] {
					&::before {
						// https://issues.liferay.com/browse/LPS-147457

						content: none;
					}
				}
			}

			&:focus {
				box-shadow: $multi-step-icon-disabled-focus-box-shadow;
			}
		}

		.multi-step-indicator-label {
			color: $multi-step-indicator-label-disabled-color;
		}

		.multi-step-divider {
			background-color: $multi-step-divider-disabled-bg;
		}
	}
}

.multi-step-title {
	color: $multi-step-title-color;
	font-size: $multi-step-title-font-size;
	font-weight: $multi-step-title-font-weight;
	line-height: normal;
	margin-bottom: $multi-step-title-margin-bottom;
	word-wrap: break-word;
}

.multi-step-item-expand {
	flex-grow: 1;
	width: $multi-step-item-width;
}

.multi-step-divider {
	background-color: $multi-step-divider-bg;
	height: $multi-step-divider-height;
	margin-left: $multi-step-icon-size;
	margin-top: -$multi-step-divider-height;
	position: relative;
	top: $multi-step-divider-top;
}

.multi-step-indicator {
	position: relative;
	width: $multi-step-indicator-width;

	.dropdown-item-indicator,
	.dropdown-item-indicator-start,
	.dropdown-item-indicator-end {
		display: none;
	}

	.complete {
		.dropdown-item-indicator,
		.dropdown-item-indicator-start,
		.dropdown-item-indicator-end {
			display: block;
		}
	}

	.multi-step-indicator-label {
		color: $multi-step-indicator-label-color;
		font-size: $multi-step-indicator-label-font-size;
		font-weight: $multi-step-indicator-label-font-weight;
		left: 50%;
		max-width: $multi-step-indicator-label-max-width;
		position: absolute;
		transform: translateX(-50%);

		@include text-truncate;
	}
}

.multi-step-icon {
	align-items: center;
	background-color: $multi-step-icon-bg;
	border-color: $multi-step-icon-border-color;
	border-radius: $multi-step-icon-border-radius;
	border-style: $multi-step-icon-border-style;
	border-width: $multi-step-icon-border-width;
	color: $multi-step-icon-color;
	cursor: $multi-step-icon-cursor;
	display: flex;
	font-size: $multi-step-icon-font-size;
	font-weight: $multi-step-icon-font-weight;
	justify-content: center;
	height: $multi-step-icon-size;
	padding-bottom: $multi-step-icon-padding-bottom;
	padding-left: $multi-step-icon-padding-left;
	padding-right: $multi-step-icon-padding-right;
	padding-top: $multi-step-icon-padding-top;
	transition: $multi-step-icon-transition;
	width: $multi-step-icon-size;

	&:hover {
		background-color: $multi-step-icon-hover-bg;
		color: $multi-step-icon-hover-color;
		text-decoration: $multi-step-icon-hover-text-decoration;
	}

	&:focus {
		background-color: $multi-step-icon-focus-bg;
		box-shadow: $multi-step-icon-focus-box-shadow;
		color: $multi-step-icon-focus-color;
		outline: $multi-step-icon-focus-outline;
		text-decoration: $multi-step-icon-focus-text-decoration;
	}

	&[data-multi-step-icon]::before {
		content: $data-multi-step-icon-before-content;
		height: $multi-step-icon-size;
		line-height: $multi-step-icon-size;
		text-align: center;
		width: $multi-step-icon-size;
	}

	.btn-link {
		vertical-align: baseline;
	}

	.lexicon-icon {
		height: $multi-step-icon-font-size;
		margin-top: 0;
		width: $multi-step-icon-font-size;
	}
}

.multi-step-item-fixed-width {
	&.multi-step-item,
	.multi-step-item-expand {
		flex-grow: 0;
		width: $multi-step-item-fixed-width;
	}
}

.multi-step-nav-collapse-sm {
	@include media-breakpoint-down(sm) {
		flex-wrap: nowrap;

		&.multi-step-indicator-label-top {
			.multi-step-indicator-label {
				bottom: auto;
				top: 0;
			}
		}

		&.multi-step-indicator-label-bottom {
			.multi-step-indicator-label {
				bottom: 0;
				top: auto;
			}
		}

		&.multi-step-title-center {
			.multi-step-title {
				margin-left: 0;
				max-width: none;
				transform: none;
			}
		}

		.active {
			&.multi-step-item {
				position: static;
			}

			.multi-step-title {
				display: block;
				left: 0;
				position: absolute;
				right: 0;
				text-align: center;
				top: 0;
			}

			.multi-step-indicator {
				position: static;
			}

			.multi-step-indicator-label {
				display: block;
				left: 0;
				max-width: none;
				position: absolute;
				right: 0;
				text-align: center;
				transform: none;
				width: auto;
			}
		}

		.multi-step-item {
			margin-bottom: 0;
			min-width: $multi-step-icon-size;
			padding-top: $multi-step-title-font-size + $multi-step-title-margin-bottom + ($multi-step-divider-height / 2);
		}

		.multi-step-title {
			display: none;
		}

		.multi-step-indicator-label {
			display: none;
		}
	}
}