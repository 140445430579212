.date-picker {
	.input-group-text {
		@include clay-input-group-text-variant($date-picker-input-group-text);
	}
}

// Date Picker Dropdown Menu

.date-picker-dropdown-menu {
	@include clay-dropdown-menu-variant($date-picker-dropdown-menu);
}

// Date Picker Nav

.date-picker-nav {
	@include clay-row($date-picker-nav-row);

	.nav-btn {
		@include clay-button-variant($date-picker-nav-btn);
	}

	.nav-btn-monospaced {
		@include clay-button-variant($date-picker-nav-btn-monospaced);
	}

	select.form-control {
		@include clay-select-variant($date-picker-nav-select);
	}
}

.date-picker-nav-item {
	align-items: center;
	display: flex;
	padding-left: 0.25rem;
	padding-right: 0.25rem;
}

.date-picker-nav-item-expand {
	flex-grow: 1;
}

.date-picker-nav-controls {
	display: flex;
	justify-content: flex-end;
}

// Date Picker Calendar

.date-picker-calendar {
	@include clay-container($date-picker-calendar-container);
}

.date-picker-calendar-header {
	@include clay-container($date-picker-calendar-header-container);
}

.date-picker-calendar-body {
	@include clay-container($date-picker-calendar-body-container);
}

.date-picker-calendar-footer {
	@include clay-container($date-picker-calendar-footer-container);
}

.date-picker-calendar-item {
	@include clay-button-variant($date-picker-calendar-item);
}

// Date Picker Days

.date-picker-row {
	@include clay-row($date-picker-row);
}

.date-picker-days-row {
	@include clay-row($date-picker-days-row);
}

.date-picker-day {
	@include clay-button-variant($date-picker-day);
}

// Date Picker Date

.date-picker-date-row {
	@include clay-row($date-picker-date-row);
}

.date-picker-date {
	@include clay-button-variant($date-picker-date);
}

// Previous Month Date

.previous-month-date {
	@include clay-button-variant($date-picker-previous-month-date);
}

// Next Month Date

.next-month-date {
	@include clay-button-variant($date-picker-next-month-date);
}