$input-border-top-width: 0.0625rem !default; // 1px
$input-border-right-width: 0.0625rem !default; // 1px
$input-border-bottom-width: 0.0625rem !default; // 1px
$input-border-left-width: 0.0625rem !default; // 1px

$input-border-style: null !default;

$input-font-size: $font-size-base !default;
$input-font-size-mobile: null !default;
$input-height-mobile: null !default;
$input-height-inner-mobile: null !default;

$input-font-size-lg: $font-size-lg !default;
$input-font-size-lg-mobile: null !default;
$input-height-lg-mobile: null !default;
$input-height-inner-lg-mobile: null !default;

$input-font-size-sm: $font-size-sm !default;
$input-font-size-sm-mobile: null !default;
$input-height-sm-mobile: null !default;
$input-height-inner-sm-mobile: null !default;

$input-disabled-border-color: null !default;
$input-disabled-color: null !default;
$input-disabled-cursor: $disabled-cursor !default;
$input-disabled-opacity: null !default;
$input-placeholder-disabled-color: null !default;

$input-placeholder-focus-color: null !default;

$input-label-color: null !default;
$input-label-focus-color: null !default;
$input-label-font-size: null !default;
$input-label-font-size-mobile: null !default;
$input-label-font-weight: null !default;
$input-label-margin-bottom: $label-margin-bottom !default;

$input-label-for-cursor: $link-cursor !default;

$input-label-disabled-color: $gray-600 !default;
$input-label-disabled-cursor: $disabled-cursor !default;

$input-label-reference-mark-color: $warning !default;
$input-label-reference-mark-font-size: null !default;
$input-label-reference-mark-spacer: null !default;
$input-label-reference-mark-vertical-align: null !default;

/// @deprecated after v2.18.0 use the Sass map `$input-readonly` instead

$input-readonly-bg: null !default;

/// @deprecated after v2.18.0 use the Sass map `$input-readonly` instead

$input-readonly-border-color: null !default;

/// @deprecated after v2.18.0 use the Sass map `$input-readonly` instead

$input-readonly-color: null !default;

/// @deprecated after v2.18.0 use the Sass map `$input-readonly` instead

$input-readonly-cursor: null !default;

/// @deprecated after v2.18.0 use the Sass map `$input-readonly` instead

$input-readonly-focus-bg: null !default;

/// @deprecated after v2.18.0 use the Sass map `$input-readonly` instead

$input-readonly-focus-border-color: null !default;

/// @deprecated after v2.18.0 use the Sass map `$input-readonly` instead

$input-readonly-focus-color: null !default;

$input-readonly: () !default;
$input-readonly: map-deep-merge((
	bg: $input-readonly-bg,
	border-color: $input-readonly-border-color,
	color: $input-readonly-color,
	cursor: $input-readonly-cursor,
	focus-bg: $input-readonly-focus-bg,
	focus-border-color: $input-readonly-focus-border-color,
	focus-color: $input-readonly-focus-color,
), $input-readonly);

$input-plaintext-readonly: () !default;
$input-plaintext-readonly: map-deep-merge((
	border-radius: $input-border-radius,
	outline: 0,
	transition: $input-transition,
	focus-box-shadow: $input-focus-box-shadow,
), $input-plaintext-readonly);

$input-textarea-height: 150px !default;
$input-textarea-height-lg: 190px !default;
$input-textarea-height-sm: 120px !default;

// Form Control Label (Labels inside Form Control Tag Group)

$form-control-label-size: () !default;
$form-control-label-size: map-deep-merge((
	border-width: 0.0625rem, // 1px
	height: 1.25rem, // 20px
	margin-bottom: 0.25rem, // 4px
	margin-right: 0.5rem, // 8px
	margin-top: 0.25rem, // 4px
), $form-control-label-size);

// Form Control Tag Group

$form-control-tag-group-padding-y: ($input-height - $input-border-bottom-width - $input-border-top-width - map-get($form-control-label-size, height) - (map-get($form-control-label-size, margin-bottom)) - (map-get($form-control-label-size, margin-top))) / 2 !default;

$form-control-inset-min-height: $input-font-size * $input-line-height !default;
$form-control-inset-margin-y: ($input-height - $input-border-bottom-width - $input-border-top-width - ($form-control-tag-group-padding-y * 2) - $form-control-inset-min-height) / 2 !default;

$form-control-tag-group: () !default;
$form-control-tag-group: map-deep-merge((
	align-items: center,
	display: flex,
	flex-wrap: wrap,
	height: auto,
	padding-bottom: $form-control-tag-group-padding-y,
	padding-top: $form-control-tag-group-padding-y,
), $form-control-tag-group);

$form-control-tag-group-autofit-row: () !default;
$form-control-tag-group-autofit-row: map-deep-merge((
		align-items: center,
		flex-grow: 1,
		margin-left: -0.5rem,
		margin-right: -0.5rem,
		width: auto,
), $form-control-tag-group-autofit-row);

$form-control-tag-group-autofit-col: () !default;
$form-control-tag-group-autofit-col: map-deep-merge((
	padding-left: 0.5rem,
	padding-right: 0.5rem,
), $form-control-tag-group-autofit-col);

$form-control-tag-group-input-group-item: () !default;
$form-control-tag-group-input-group-item: map-deep-merge((
	align-items: flex-start,
), $form-control-tag-group-input-group-item);

$form-control-tag-group-inline-item: () !default;
$form-control-tag-group-inline-item: map-deep-merge((
	height: $form-control-inset-min-height,
	margin-bottom: $form-control-inset-margin-y,
	margin-top: $form-control-inset-margin-y,
), $form-control-tag-group-inline-item);

$form-control-tag-group-btn: () !default;
$form-control-tag-group-btn: map-deep-merge((
	height: $form-control-inset-min-height,
	line-height: 1,
	margin-bottom: $form-control-inset-margin-y,
	margin-top: $form-control-inset-margin-y,
	padding-bottom: 0,
	padding-left: $btn-padding-x-sm,
	padding-right: $btn-padding-x-sm,
	padding-top: 0,
), $form-control-tag-group-btn);

$form-control-tag-group-btn-monospaced: () !default;
$form-control-tag-group-btn-monospaced: map-deep-merge((
	height: $form-control-inset-min-height,
	line-height: 1,
	margin-bottom: $form-control-inset-margin-y,
	margin-top: $form-control-inset-margin-y,
	padding-left: 0,
	padding-right: 0,
	width: $form-control-inset-min-height,
), $form-control-tag-group-btn-monospaced);

// Form Group

$form-group-margin-bottom-mobile: null !default;

$form-group-sm-margin-bottom: 1rem !default; // 16px
$form-group-sm-margin-bottom-mobile: null !default;
$form-group-sm-input-label-margin-bottom: 0.25rem !default; // 4px
$form-group-sm-item-label-spacer: 1.75rem !default; // 28px

// Form Group Autofit

$form-group-autofit-margin-bottom: $form-group-margin-bottom !default;

// Form Control File

$input-file-cursor: $link-cursor !default;

// Form Check

$form-check-input-cursor: $link-cursor !default;
$form-check-input-disabled-cursor: $disabled-cursor !default;

$form-check-label-cursor: $input-label-for-cursor !default;

$form-check-label-text-margin-left: -0.1875rem !default; // 3px
$form-check-label-text-padding-left: 0.5rem !default; // 8px
$form-check-label-text-disabled-cursor: $disabled-cursor !default;

// Input Variants

$form-feedback-warning-color: $warning !default;

$input-danger-bg: null !default;
$input-danger-focus-bg: null !default;
$input-danger-border-color: $form-feedback-invalid-color !default;
$input-danger-focus-border-color: null !default;
$input-danger-box-shadow: null !default;
$input-danger-focus-box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25) !default;
$input-danger-color: null !default;
$input-danger-focus-color: null !default;

$input-danger-readonly: () !default;

$input-danger-checkbox-label-color: $form-feedback-invalid-color !default;
$input-danger-label-color: null !default;
$input-danger-select-icon-color: $input-danger-border-color !default;
$input-danger-select-icon: clay-str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$input-danger-select-icon-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23") !default;

$input-success-bg: null !default;
$input-success-focus-bg: null !default;
$input-success-border-color: $form-feedback-valid-color !default;
$input-success-focus-border-color: null !default;
$input-success-box-shadow: null !default;
$input-success-focus-box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-valid-color, 0.25) !default;
$input-success-color: null !default;
$input-success-focus-color: null !default;

$input-success-readonly: () !default;

$input-success-checkbox-label-color: $form-feedback-valid-color !default;
$input-success-label-color: null !default;
$input-success-select-icon-color: $input-success-border-color !default;
$input-success-select-icon: clay-str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$input-success-select-icon-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23") !default;

$input-warning-bg: null !default;
$input-warning-focus-bg: null !default;
$input-warning-border-color: $form-feedback-warning-color !default;
$input-warning-focus-border-color: null !default;
$input-warning-box-shadow: null !default;
$input-warning-focus-box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-warning-color, 0.25) !default;
$input-warning-color: null !default;
$input-warning-focus-color: null !default;

$input-warning-readonly: () !default;

$input-warning-checkbox-label-color: $form-feedback-warning-color !default;
$input-warning-label-color: null !default;
$input-warning-select-icon-color: $input-warning-border-color !default;
$input-warning-select-icon: clay-str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$input-warning-select-icon-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23") !default;

// Select Element

$input-select-bg: null !default;
$input-select-focus-bg: null !default;

$input-select-bg-position: right $input-btn-padding-x center !default;
$input-select-bg-size: $custom-select-bg-size !default;
$input-select-cursor: $link-cursor !default;
$input-select-padding-bottom: null !default;
$input-select-padding-left: null !default;
$input-select-padding-right: $custom-select-indicator-padding + $input-btn-padding-x !default;
$input-select-padding-top: null !default;

$input-select-icon-color: $input-color !default;
$input-select-icon: clay-str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$input-select-icon-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23") !default;

$input-select-icon-focus-color: null !default;
$input-select-icon-focus: null !default;

$input-select-icon-disabled-color: null !default;
$input-select-icon-disabled: null !default;

// Form Feedback

$form-feedback-font-weight: null !default;
$form-feedback-margin-top: 0.25rem !default;

$form-feedback-indicator-margin-x: 0.125rem !default;

$form-text-color: $gray-600 !default;
$form-text-font-size: 0.875rem !default; // 14px
$form-text-font-weight: null !default;

// Form Group Autofit

$form-group-item-label-max-width: 12.5rem !default; // 200px
$form-group-item-label-spacer: 2rem !default; // 32px

// Input Group

$input-group-addon-color: null !default;
$input-group-addon-font-weight: null !default;
$input-group-addon-min-width: 2.375rem !default; // 38px
$input-group-addon-padding-x: null !default;
$input-group-addon-padding-y: null !default;

// Input Group Secondary

$input-group-secondary-addon-bg: $secondary !default;
$input-group-secondary-addon-border-color: $secondary !default;
$input-group-secondary-addon-border-width: 0.0625rem !default; // 1px
$input-group-secondary-addon-color: $white !default;

// Input Group Item

$input-group-item-margin-left: 0.5rem !default;

// Input Group Inset

$input-group-inset-item-color: $input-group-addon-color !default;
$input-group-inset-item-padding-left: 5px !default;
$input-group-inset-item-padding-right: 5px !default;

$input-group-inset-item-btn: () !default;
$input-group-inset-item-btn: map-deep-merge((
	height: 75%,
	line-height: 1,
	margin-left: 0.125rem,
	margin-right: 0.125rem,
	padding-bottom: 0,
	padding-left: 0.5rem,
	padding-right: 0.5rem,
	padding-top: 0,
), $input-group-inset-item-btn);

$input-group-inset-form-file-btn: () !default;
$input-group-inset-form-file-btn: map-deep-merge((
	height: 100%,
), $input-group-inset-form-file-btn);

// Input Group Lg

$input-group-addon-min-width-lg: 3rem !default; // 48px
$input-group-addon-padding-x-lg: $input-padding-x-lg !default;
$input-group-addon-padding-y-lg: null !default;

$input-group-lg-item-btn: () !default; // .input-group-lg .input-group-item
$input-group-lg-item-btn: map-deep-merge((
	breakpoint-down: null,
	font-size: $btn-font-size-lg,
	inline-item-font-size: $btn-inline-item-font-size-lg,
	section-font-size: $btn-section-font-size-lg,
	font-size-mobile: $btn-font-size-lg-mobile,
	padding-bottom-mobile: $btn-padding-y-lg-mobile,
	padding-left-mobile: $btn-padding-x-lg-mobile,
	padding-right-mobile: $btn-padding-x-lg-mobile,
	padding-top-mobile: $btn-padding-y-lg-mobile,
), $input-group-lg-item-btn);

$input-group-lg-item-btn-monospaced: () !default;
$input-group-lg-item-btn-monospaced: map-deep-merge((
	breakpoint-down: null,
	height: $btn-monospaced-size-lg,
	line-height: 1,
	padding-bottom: $btn-monospaced-padding-y-lg,
	padding-left: $btn-monospaced-padding-x-lg,
	padding-right: $btn-monospaced-padding-x-lg,
	padding-top: $btn-monospaced-padding-y-lg,
	width: $btn-monospaced-size-lg,
	height-mobile: $btn-monospaced-size-lg-mobile,
	width-mobile: $btn-monospaced-size-lg-mobile,
), $input-group-lg-item-btn-monospaced);

$input-group-lg-inset-item-btn: () !default;

$input-group-lg-inset-item-form-file-btn: () !default;
$input-group-lg-inset-item-form-file-btn: map-deep-merge((
	breakpoint-down: null,
	height: 100%,
	line-height: 1,
	padding-bottom: 0,
	padding-left: 0.75rem,
	padding-right: 0.75rem,
	padding-top: 0,
), $input-group-lg-inset-item-form-file-btn);

// Input Group Sm

$input-group-addon-min-width-sm: 1.9375rem !default; // 31px
$input-group-addon-padding-x-sm: $input-padding-x-sm !default;
$input-group-addon-padding-y-sm: null !default;

$input-group-sm-item-btn: () !default;
$input-group-sm-item-btn: map-deep-merge((
	font-size: $btn-font-size-sm,
	inline-item-font-size: $btn-inline-item-font-size-sm,
	section-font-size: $btn-section-font-size-sm,
	font-size-mobile: $btn-font-size-sm-mobile,
	padding-bottom-mobile: $btn-padding-y-sm-mobile,
	padding-left-mobile: $btn-padding-x-sm-mobile,
	padding-right-mobile: $btn-padding-x-sm-mobile,
	padding-top-mobile: $btn-padding-y-sm-mobile,
), $input-group-sm-item-btn);

$input-group-sm-item-btn-monospaced: () !default;
$input-group-sm-item-btn-monospaced: map-deep-merge((
	height: $btn-monospaced-size-sm,
	line-height: 1,
	padding-bottom: $btn-monospaced-padding-y-sm,
	padding-left: $btn-monospaced-padding-x-sm,
	padding-right: $btn-monospaced-padding-x-sm,
	padding-top: $btn-monospaced-padding-y-sm,
	width: $btn-monospaced-size-sm,
	height-mobile: $btn-monospaced-size-sm-mobile,
	width-mobile: $btn-monospaced-size-sm-mobile,
), $input-group-sm-item-btn-monospaced);

$input-group-sm-inset-item-btn: () !default;
$input-group-sm-inset-item-btn: map-deep-merge((
	line-height: 1,
	padding-bottom: 0,
	padding-left: 0.5rem,
	padding-right: 0.5rem,
	padding-top: 0,
), $input-group-sm-inset-item-btn);

$input-group-sm-inset-item-form-file-btn: () !default;
$input-group-sm-inset-item-form-file-btn: map-deep-merge((
	height: 100%,
	line-height: 1,
	padding-bottom: 0,
	padding-left: 0.5rem,
	padding-right: 0.5rem,
	padding-top: 0,
), $input-group-sm-inset-item-form-file-btn);

// Input Group Stacked

$input-group-stacked-sm-down: () !default;
$input-group-stacked-sm-down: map-deep-merge((
	breakpoint: sm,
	item-margin-bottom: 0.5rem,
	item-margin-left: 0,
	shrink-margin-right: 0.5rem
), $input-group-stacked-sm-down);