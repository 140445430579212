.management-bar {
	@include clay-navbar-size($management-bar-size);
	@include clay-navbar-variant($management-bar-base);

	&.navbar-nowrap {
		.navbar-text {
			white-space: normal;
			word-wrap: break-word;
		}
	}
}

.management-bar-light {
	@include clay-navbar-variant($management-bar-light);
}

.management-bar-primary {
	@include clay-navbar-variant($management-bar-primary);
}