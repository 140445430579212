$bg-checkered-fg: $gray-200 !default;
$inline-scroller-max-height: 125px !default;

// Autofit Row

$autofit-col-expand-min-width: 3.125rem !default; // 50px

$autofit-padded-col-padding-x: 0.5rem !default; // 8px
$autofit-padded-col-padding-y: 0.25rem !default; // 4px

// Close

$close: () !default;
$close: map-deep-merge((
	color: $black,
	opacity: 0.5,
	hover-opacity: 0.75,
	focus-opacity: 0.75,
	disabled-opacity: 0.25,
	btn-focus-box-shadow: $input-btn-focus-box-shadow,
	btn-focus-outline: 0,
	align-items: center,
	border-radius: $btn-border-radius-sm,
	display: inline-flex,
	font-size: 1rem,
	justify-content: center,
	height: 2rem,
	text-align: center,
	width: 2rem,
	transition: $btn-transition,
	lexicon-icon-margin-top: 0
), $close);

// Heading

$heading-spacer-x: 1rem !default; // 16px

$heading-text-margin-bottom: auto !default;
$heading-text-margin-top: auto !default;

// Inline Item

$inline-item-lexicon-icon-margin-top: -0.1em !default;
$inline-item-spacer-x: 0.5rem !default; // 8px

$inline-item-loading-animation-font-size: 1.25em !default;

$page-header-bg: $gray-200 !default;