////
/// @group alerts
////

$alert-border-style: solid !default;

/// @deprecated as of v2.12.0 use the Sass map `$alert-close` instead

$alert-close-font-size: 0.875rem !default; // 14px

/// @deprecated as of v2.12.0 use the Sass map `$alert-close` instead

$alert-close-height: 2rem !default; // 32px

/// @deprecated as of v2.12.0 use the Sass map `$alert-close` instead

$alert-close-line-height: null !default;

/// @deprecated as of v2.12.0 use the Sass map `$alert-close` instead

$alert-close-opacity: null !default;

/// @deprecated as of v2.12.0 use the Sass map `$alert-close` instead

$alert-close-margin-left: null !default;

/// @deprecated as of v2.12.0 use the Sass map `$alert-close` instead

$alert-close-padding-bottom: 0 !default;

/// @deprecated as of v2.12.0 use the Sass map `$alert-close` instead

$alert-close-padding-left: 0 !default;

/// @deprecated as of v2.12.0 use the Sass map `$alert-close` instead

$alert-close-padding-right: 0 !default;

/// @deprecated as of v2.12.0 use the Sass map `$alert-close` instead

$alert-close-padding-top: 0 !default;

/// @deprecated as of v2.12.0 use the Sass map `$alert-close` instead

$alert-close-position-right: 0.5rem !default; // 8px

/// @deprecated as of v2.12.0 use the Sass map `$alert-close` instead

$alert-close-position-top: 0.75rem !default; // 12px

/// @deprecated as of v2.12.0 use the Sass map `$alert-close` instead

$alert-close-width: $alert-close-height !default;

$alert-close: () !default;
$alert-close: map-deep-merge((
	font-size: $alert-close-font-size,
	height: $alert-close-height,
	line-height: $alert-close-line-height,
	margin-left: $alert-close-margin-left,
	opacity: $alert-close-opacity,
	padding-bottom: $alert-close-padding-bottom,
	padding-left: $alert-close-padding-left,
	padding-right: $alert-close-padding-right,
	padding-top: $alert-close-padding-top,
	right: $alert-close-position-right,
	top: $alert-close-position-top,
	width: $alert-close-width,
), $alert-close);

$alert-font-size: null !default;

$alert-lead-font-size: null !default;
$alert-lead-font-weight: null !default;
$alert-lead-spacer-x: 0.3125rem !default; // 5px

$alert-link-decoration: null !default;
$alert-link-hover-decoration: underline !default;

$alert-indicator-font-size: 1.25rem !default;

$alert-title: () !default;

$alert-subtitle: () !default;

// Alert Dismissible

$alert-dismissible-padding-bottom: null !default;
$alert-dismissible-padding-left: null !default;
$alert-dismissible-padding-right: 2.5rem !default; // 40px
$alert-dismissible-padding-top: null !default;

// Alert Fluid

$alert-fluid-border-bottom-width: 1px !default;
$alert-fluid-border-left-width: 0 !default;
$alert-fluid-border-right-width: 0 !default;
$alert-fluid-border-top-width: 0 !default;

$alert-fluid-border-width: $alert-fluid-border-top-width $alert-fluid-border-right-width $alert-fluid-border-bottom-width $alert-fluid-border-left-width !default;
$alert-fluid-margin-bottom: 0 !default;

// Alert Notification

$alert-notifications-absolute-bottom: null !default;
$alert-notifications-absolute-left: null !default;
$alert-notifications-absolute-right: 0.5rem !default; // 8px
$alert-notifications-absolute-top: 4.75rem !default; // 76px

$alert-notifications-absolute-bottom-mobile: null !default;
$alert-notifications-absolute-left-mobile: 0.5rem !default; // 8px
$alert-notifications-absolute-right-mobile: 0.5rem !default; // 8px
$alert-notifications-absolute-top-mobile: 4.25rem!default; // 68px

$alert-notifications-fixed-bottom: 1.25rem !default; // 20px
$alert-notifications-fixed-left: 1.25rem !default; // 20px
$alert-notifications-fixed-right: null !default;
$alert-notifications-fixed-top: null !default;

$alert-notifications-fixed-bottom-mobile: 1rem !default; // 16px
$alert-notifications-fixed-left-mobile: ($grid-gutter-width / 2) !default;
$alert-notifications-fixed-right-mobile: ($grid-gutter-width / 2) !default;
$alert-notifications-fixed-top-mobile: null !default; // 68px

$alert-notifications-box-shadow: null !default;
$alert-notifications-max-width: 22.5rem !default; // 360px

// Alert Variants

$alert-primary-bg: null !default;
$alert-primary-border-color: null !default;
$alert-primary-color: null !default;
$alert-primary-close-color: null !default;
$alert-primary-close-hover-color: null !default;
$alert-primary-lead-color: null !default;
$alert-primary-link-color: null !default;
$alert-primary-link-hover-color: null !default;

$alert-secondary-bg: null !default;
$alert-secondary-border-color: null !default;
$alert-secondary-color: null !default;
$alert-secondary-close-color: null !default;
$alert-secondary-close-hover-color: null !default;
$alert-secondary-lead-color: null !default;
$alert-secondary-link-color: null !default;
$alert-secondary-link-hover-color: null !default;

$alert-success-bg: null !default;
$alert-success-border-color: null !default;
$alert-success-color: null !default;
$alert-success-close-color: null !default;
$alert-success-close-hover-color: null !default;
$alert-success-lead-color: null !default;
$alert-success-link-color: null !default;
$alert-success-link-hover-color: null !default;

$alert-info-bg: null !default;
$alert-info-border-color: null !default;
$alert-info-color: null !default;
$alert-info-close-color: null !default;
$alert-info-close-hover-color: null !default;
$alert-info-lead-color: null !default;
$alert-info-link-color: null !default;
$alert-info-link-hover-color: null !default;

$alert-warning-bg: null !default;
$alert-warning-border-color: null !default;
$alert-warning-color: null !default;
$alert-warning-close-color: null !default;
$alert-warning-close-hover-color: null !default;
$alert-warning-lead-color: null !default;
$alert-warning-link-color: null !default;
$alert-warning-link-hover-color: null !default;

$alert-danger-bg: null !default;
$alert-danger-border-color: null !default;
$alert-danger-color: null !default;
$alert-danger-close-color: null !default;
$alert-danger-close-hover-color: null !default;
$alert-danger-lead-color: null !default;
$alert-danger-link-color: null !default;
$alert-danger-link-hover-color: null !default;

$alert-light-bg: null !default;
$alert-light-border-color: null !default;
$alert-light-color: null !default;
$alert-light-close-color: null !default;
$alert-light-close-hover-color: null !default;
$alert-light-lead-color: null !default;
$alert-light-link-color: null !default;
$alert-light-link-hover-color: null !default;

$alert-dark-bg: null !default;
$alert-dark-border-color: null !default;
$alert-dark-color: null !default;
$alert-dark-close-color: null !default;
$alert-dark-close-hover-color: null !default;
$alert-dark-lead-color: null !default;
$alert-dark-link-color: null !default;
$alert-dark-link-hover-color: null !default;

$alert-palette: () !default;
$alert-palette: map-deep-merge((
	primary: (
		bg: $alert-primary-bg,
		border-color: $alert-primary-border-color,
		color: $alert-primary-color,
		close-color: $alert-primary-close-color,
		close-hover-color: $alert-primary-close-hover-color,
		lead-color: $alert-primary-lead-color,
		link-color: $alert-primary-link-color,
		link-hover-color: $alert-primary-link-hover-color
	),
	secondary: (
		bg: $alert-secondary-bg,
		border-color: $alert-secondary-border-color,
		color: $alert-secondary-color,
		close-color: $alert-secondary-close-color,
		close-hover-color: $alert-secondary-close-hover-color,
		lead-color: $alert-secondary-lead-color,
		link-color: $alert-secondary-link-color,
		link-hover-color: $alert-secondary-link-hover-color
	),
	success: (
		bg: $alert-success-bg,
		border-color: $alert-success-border-color,
		color: $alert-success-color,
		close-color: $alert-success-close-color,
		close-hover-color: $alert-success-close-hover-color,
		lead-color: $alert-success-lead-color,
		link-color: $alert-success-link-color,
		link-hover-color: $alert-success-link-hover-color
	),
	info: (
		bg: $alert-info-bg,
		border-color: $alert-info-border-color,
		color: $alert-info-color,
		close-color: $alert-info-close-color,
		close-hover-color: $alert-info-close-hover-color,
		lead-color: $alert-info-lead-color,
		link-color: $alert-info-link-color,
		link-hover-color: $alert-info-link-hover-color
	),
	warning: (
		bg: $alert-warning-bg,
		border-color: $alert-warning-border-color,
		color: $alert-warning-color,
		close-color: $alert-warning-close-color,
		close-hover-color: $alert-warning-close-hover-color,
		lead-color: $alert-warning-lead-color,
		link-color: $alert-warning-link-color,
		link-hover-color: $alert-warning-link-hover-color
	),
	danger: (
		bg: $alert-danger-bg,
		border-color: $alert-danger-border-color,
		color: $alert-danger-color,
		close-color: $alert-danger-close-color,
		close-hover-color: $alert-danger-close-hover-color,
		lead-color: $alert-danger-lead-color,
		link-color: $alert-danger-link-color,
		link-hover-color: $alert-danger-link-hover-color
	),
	light: (
		bg: $alert-light-bg,
		border-color: $alert-light-border-color,
		color: $alert-light-color,
		close-color: $alert-light-close-color,
		close-hover-color: $alert-light-close-hover-color,
		lead-color: $alert-light-lead-color,
		link-color: $alert-light-link-color,
		link-hover-color: $alert-light-link-hover-color
	),
	dark: (
		bg: $alert-dark-bg,
		border-color: $alert-dark-border-color,
		color: $alert-dark-color,
		close-color: $alert-dark-close-color,
		close-hover-color: $alert-dark-close-hover-color,
		lead-color: $alert-dark-lead-color,
		link-color: $alert-dark-link-color,
		link-hover-color: $alert-dark-link-hover-color
	),
), $alert-palette);