$tbar-item-padding-x: 0.25rem !default;
$tbar-item-padding-y: null !default;

// Tbar Inline {xs|sm|md|lg|xl} Down

$tbar-inline-xs-down: () !default;
$tbar-inline-xs-down: map-deep-merge((
	breakpoint-down: nth(map-keys($grid-breakpoints), 1),
	item-padding-left: 0
), $tbar-inline-xs-down);

$tbar-inline-sm-down: () !default;
$tbar-inline-sm-down: map-deep-merge((
	breakpoint-down: nth(map-keys($grid-breakpoints), 2),
	item-padding-left: 0
), $tbar-inline-sm-down);

$tbar-inline-md-down: () !default;
$tbar-inline-md-down: map-deep-merge((
	breakpoint-down: nth(map-keys($grid-breakpoints), 3),
	item-padding-left: 0
), $tbar-inline-md-down);

$tbar-inline-lg-down: () !default;
$tbar-inline-lg-down: map-deep-merge((
	breakpoint-down: nth(map-keys($grid-breakpoints), 4),
	item-padding-left: 0
), $tbar-inline-lg-down);

$tbar-inline-xl-down: () !default;
$tbar-inline-xl-down: map-deep-merge((
	breakpoint-down: nth(map-keys($grid-breakpoints), 5),
	item-padding-left: 0
), $tbar-inline-xl-down);

// Component Tbar

$component-tbar: () !default;
$component-tbar: map-deep-merge((
	border-color: $gray-300,
	border-style: solid,
	border-width: 0 0 0.0625rem 0,
	height: 3.5rem
), $component-tbar);

// Subnav Tbar

$subnav-tbar-component-title: () !default;
$subnav-tbar-component-title: map-deep-merge((
	display: inline-block,
	font-size: 0.875rem,
	font-weight: $font-weight-semi-bold,
	line-height: 1.45,
	margin-bottom: 0.25rem,
	margin-top: 0.25rem,
	max-width: 100%
), $subnav-tbar-component-title);

$subnav-tbar-component-link: () !default;
$subnav-tbar-component-link: map-deep-merge((
	color: $link-color,
	hover-color: $link-hover-color,
	font-weight: $font-weight-semi-bold
), $subnav-tbar-component-link);

$subnav-tbar-component-text: () !default;
$subnav-tbar-component-text: map-deep-merge((
	display: inline-block,
	line-height: 1.45,
	margin-bottom: 0.25rem,
	margin-top: 0.25rem,
	max-width: 100%
), $subnav-tbar-component-text);

$subnav-tbar: () !default;
$subnav-tbar: map-deep-merge((
	font-size: 0.875rem,
	section-text-align: left,
	strong-font-weight: $font-weight-semi-bold,
	item-padding-x: 0.5rem,
	btn-height: 1.5rem,
	btn-line-height: 1,
	btn-margin-y: 0.125rem,
	btn-padding-y: 0,
	btn-monospaced-margin-y: 0.125rem,
	btn-monospaced-padding: 0.25rem,
	component-link: $subnav-tbar-component-link,
	component-title: $subnav-tbar-component-title,
	component-text: $subnav-tbar-component-text,
	link-margin-y: 0.125rem,
	link-padding-x: 0.25rem,
	link-padding-y: 0.09375rem,
	link-monospaced-margin-y: 0.125rem,
	link-monospaced-size: 1.5rem
), $subnav-tbar);

// Subnav Tbar Variants

$subnav-tbar-light: () !default;
$subnav-tbar-light: map-deep-merge((
	bg-color: $light,
	color: $navbar-light-color,
	padding-y: 0.125rem
), $subnav-tbar-light);

// Subnav Tbar Primary

$subnav-tbar-primary-component-link: () !default;
$subnav-tbar-primary-component-link: map-deep-merge((
	color: $gray-900,
	hover-color: $gray-900,
	disabled-color: $gray-600,
	disabled-cursor: $disabled-cursor,
	disabled-opacity: $btn-disabled-opacity,
	disabled-text-decoration: none
), $subnav-tbar-primary-component-link);

$subnav-tbar-primary-component-label-close: () !default;
$subnav-tbar-primary-component-label-close: map-deep-merge((
	focus-color: inherit,
	disabled-color: $gray-600,
	disabled-opacity: $btn-disabled-opacity
), $subnav-tbar-primary-component-label-close);

$subnav-tbar-primary-component-label: () !default;
$subnav-tbar-primary-component-label: map-deep-merge((
	close: $subnav-tbar-primary-component-label-close
), $subnav-tbar-primary-component-label);

$subnav-tbar-primary-tbar-label-size: () !default;
$subnav-tbar-primary-tbar-label-size: map-deep-merge((
	font-size: 0.75rem,
	margin-right: 0,
	padding-x: 0.625rem,
	padding-y: 0.3125rem,
	text-transform: none
), $subnav-tbar-primary-tbar-label-size);

$subnav-tbar-primary: () !default;
$subnav-tbar-primary: map-deep-merge((
	bg-color: $primary-l2,
	padding-x: 0.25rem,
	padding-y: 0.625rem,
	item-justify-content: flex-start,
	item-padding-x: 0.25rem,
	link-monospaced-border-radius: 0,
	link-monospaced-border-width: 0,
	link-monospaced-margin-y: -0.625rem,
	link-monospaced-size: 3rem,
	component-link: $subnav-tbar-primary-component-link,
	component-label: $subnav-tbar-primary-component-label,
	tbar-label-size: $subnav-tbar-primary-tbar-label-size
), $subnav-tbar-primary);

// Subnav Tbar Primary Disabled

$subnav-tbar-primary-disabled-component-label: () !default;
$subnav-tbar-primary-disabled-component-label: map-deep-merge((
	border-color: #6C757D
), $subnav-tbar-primary-disabled-component-label);

$subnav-tbar-primary-disabled: () !default;
$subnav-tbar-primary-disabled: map-deep-merge((
	bg-color: lighten(desaturate($primary, 27.03), 37.06),
	color: #6C757D,
	component-label: $subnav-tbar-primary-disabled-component-label
), $subnav-tbar-primary-disabled);