.form-file {
	display: flex;
	position: relative;
}

.form-file-input {
	cursor: $input-file-cursor;
	height: 100%;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	width: 100%;
	z-index: 10;

	&::-webkit-file-upload-button {
		cursor: $input-file-cursor;
	}

	&:focus {
		+ .btn-primary {
			@extend %btn-primary-focus !optional;
		}

		+ .btn-secondary {
			@extend %btn-secondary-focus !optional;
		}

		+ .btn-success {
			@extend %btn-success-focus !optional;
		}

		+ .btn-warning {
			@extend %btn-warning-focus !optional;
		}

		+ .btn-danger {
			@extend %btn-danger-focus !optional;
		}

		+ .btn-light {
			@extend %btn-light-focus !optional;
		}

		+ .btn-dark {
			@extend %btn-dark-focus !optional;
		}

		+ .input-group {
			border-radius: 1px;
			box-shadow: 0 0 0 0.075rem #FFF, $btn-focus-box-shadow;
		}
	}

	&:disabled {
		cursor: $input-disabled-cursor;

		&::-webkit-file-upload-button {
			cursor: $input-disabled-cursor;
		}
	}
}

// Custom Checkbox and Radio

.custom-control {
	margin-bottom: $custom-control-margin-bottom;
	margin-top: $custom-control-margin-top;
	min-height: $custom-control-min-height;

	label {
		cursor: map-get($custom-control-label, cursor);
		font-size: $font-size-base;
	}
}

.custom-control-label {
	@include clay-container($custom-control-label);
}

label.custom-control-label {
	font-size: map-get($custom-control-label, font-size);
}

.custom-control-label-text {
	@include clay-container($custom-control-label-text);

	small,
	.small {
		@include clay-container($custom-control-label-text-small);
	}
}

// Custom Control Indicator

.custom-control-label::before {
	border-color: $custom-control-indicator-border-color;
	border-style: $custom-control-indicator-border-style;
	border-width: $custom-control-indicator-border-width;
	font-size: $custom-control-indicator-size;
	left: 0;
	top: $custom-control-indicator-position-top;
}

.custom-control-label::after {
	left: 0;
	top: $custom-control-indicator-position-top;
}

.custom-control-input {
	cursor: $form-check-input-cursor;

	&:active ~ .custom-control-label::before {
		border-color: $custom-control-indicator-active-border-color;
	}

	&:active:checked ~ .custom-control-label::before {
		background-color: $custom-control-indicator-checked-active-bg;
		border-color: $custom-control-indicator-checked-active-border-color;
	}

	&:checked ~ .custom-control-label::before {
		border-color: $custom-control-indicator-checked-border-color;
	}

	&:disabled {
		cursor: $custom-control-indicator-disabled-cursor;

		~ .custom-control-label::before {
			background-color: $custom-control-indicator-disabled-bg;
			border-color: $custom-control-indicator-disabled-border-color;
			box-shadow: none;
		}

		~ .custom-control-label {
			@include clay-container($custom-control-label-disabled);
		}
	}

	&:disabled:checked ~ .custom-control-label::before {
		background-color: $custom-control-indicator-checked-disabled-bg;
		border-color: $custom-control-indicator-checked-disabled-border-color;
	}
}

.custom-control .custom-control-input:focus ~ .custom-control-label::before {
	box-shadow: $custom-control-indicator-focus-box-shadow;
}

// Custom Checkbox

.custom-checkbox {
	.custom-control-input {
		&:checked ~ .custom-control-label::after {
			background-size: $custom-checkbox-indicator-icon-checked-bg-size;
		}

		&:indeterminate ~ .custom-control-label::before {
			background-color: $custom-checkbox-indicator-indeterminate-bg;
			border-color: $custom-control-indicator-indeterminate-border-color;
		}

		&:indeterminate ~ .custom-control-label::after {
			background-size: $custom-checkbox-indicator-icon-indeterminate-bg-size;
		}

		&:disabled:indeterminate ~ .custom-control-label::before {
			background-color: $custom-control-indicator-checked-disabled-bg;
			border-color: $custom-control-indicator-checked-disabled-border-color;
		}
	}
}

// Custom Radio

.custom-radio {
	.custom-control-input {
		&:checked ~ .custom-control-label::after {
			background-size: $custom-radio-indicator-icon-checked-bg-size;
		}

		&:disabled ~ .custom-control-label::before {
			border-color: $custom-radio-indicator-disabled-border-color;
		}

		&:disabled:checked ~ .custom-control-label::before {
			border-color: $custom-radio-indicator-checked-disabled-border-color;
		}
	}
}

// Will need to be revisited once https://github.com/twbs/bootstrap/pull/25184 is merged

.custom-control {
	padding-left: 0;

	label {
		display: inline;
		margin-bottom: 0;
	}
}

.custom-control-input {
	height: $custom-control-indicator-size;
	left: 0;
	top: $custom-control-indicator-position-top;
	width: $custom-control-indicator-size;
	z-index: 1;
}

.custom-control-label::before {
	display: block;
	float: left;
	position: relative;
}

.custom-control-inline {
	margin-right: 0;

	+ .custom-control-inline {
		margin-left: $custom-control-spacer-x;
	}
}