// Highlight inline text
// @param $bg-color - Color of the highlight
// @param $color - Colors the text

@mixin clay-highlight-text($bg-color: theme-color-level(warning, -10), $color: $body-color) {
	background-color: $bg-color;
	box-decoration-break: clone;
	box-shadow: -0.25em 0 0 $bg-color;
	color: $color;
	display: inline;
	line-height: normal;
	padding: 2px 0.25em 3px 0;
	position: relative;
	white-space: pre-wrap;

	&:before {
		background-color: $bg-color;
		bottom: 0;
		content: '';
		display: block;
		position: absolute;
		right: 100%;
		top: 0;
		width: 0.25em;
		z-index: -1;
	}
}