.quick-action-menu {
	align-items: center;
	bottom: 0;
	display: none;
	font-size: $quick-action-font-size;
	left: auto;
	padding-bottom: $quick-action-padding-y;
	padding-left: $quick-action-padding-x;
	padding-right: $quick-action-padding-x;
	padding-top: $quick-action-padding-y;
	position: absolute;
	right: 100%;
	top: 0;
}

.quick-action-item {
	@include border-radius($quick-action-item-border-radius);

	display: flex;
	line-height: 1;
	margin-bottom: $quick-action-item-margin-y;
	margin-left: $quick-action-item-margin-x;
	margin-right: $quick-action-item-margin-x;
	margin-top: $quick-action-item-margin-y;
	min-height: $quick-action-item-min-height;
	min-width: $quick-action-item-min-width;
	padding-bottom: $quick-action-item-padding-y;
	padding-left: $quick-action-item-padding-x;
	padding-right: $quick-action-item-padding-x;
	padding-top: $quick-action-item-padding-y;

	&:disabled,
	&.disabled {
		cursor: $quick-action-item-disabled-cursor;
		opacity: $quick-action-item-disabled-opacity;
	}

	.lexicon-icon {
		margin-top: 0;
	}
}