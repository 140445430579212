$collapse-icon-padding-left: null !default;
$collapse-icon-padding-right: 2.28125rem !default; // 45px

$collapse-icon-position-bottom: null !default;
$collapse-icon-position-left: null !default;
$collapse-icon-position-right: 0.9375rem !default; // 15px
$collapse-icon-position-top: clay-collapse-icon-align($nav-link-padding-y, 0.0625rem, 0.9375em) !default;

$lexicon-icon-size: 1em !default; // 16px

$lexicon-icon-sm-font-size: 0.5rem !default; // 8px
$lexicon-icon-lg-font-size: 2rem !default; // 32px
$lexicon-icon-xl-font-size: 8rem !default; // 128px

$order-arrow-down-inactive-color: rgba($black, 0.9) !default;
$order-arrow-up-inactive-color: $order-arrow-down-inactive-color !default;