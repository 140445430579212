$modal-sm: 320px !default;
$modal-md: 600px !default;
$modal-lg: 896px !default;
$modal-xl: 1140px !default;

$modal-backdrop-bg: $gray-800 !default;
$modal-backdrop-opacity: 0.8 !default;

$modal-content-box-shadow-xs: 0 0 3px 1px rgba(0, 0, 0, 0.2) !default;
$modal-content-border-color: transparent !default;

$modal-inner-padding: 1.5rem !default; // 24px

$modal-header-border-color: $gray-300 !default;
$modal-header-padding: 1.5rem !default; // 24px

$modal-title-font-size: 1.25rem !default; // 20px
$modal-title-font-weight: $font-weight-bold !default;

// Modal Success

$modal-success: () !default;
$modal-success: map-deep-merge((
	header-bg: $success-l2,
	header-border-color: $success-l1,
	header-color: $success,
	header-close: (
		color: $success,
		hover-color: inherit,
		focus-color: inherit,
		disabled-color: inherit
	)
), $modal-success);

// Modal Info

$modal-info: () !default;
$modal-info: map-deep-merge((
	header-bg: $info-l2,
	header-border-color: $info-l1,
	header-color: $info,
	header-close: (
		color: $info,
		hover-color: inherit,
		focus-color: inherit,
		disabled-color: inherit
	)
), $modal-info);

// Modal Warning

$modal-warning: () !default;
$modal-warning: map-deep-merge((
	header-bg: $warning-l2,
	header-border-color: $warning-l1,
	header-color: $warning,
	header-close: (
		color: $warning,
		hover-color: inherit,
		focus-color: inherit,
		disabled-color: inherit
	)
), $modal-warning);

// Modal Danger

$modal-danger: () !default;
$modal-danger: map-deep-merge((
	header-bg: $danger-l2,
	header-border-color: $danger-l1,
	header-color: $danger,
	header-close: (
		color: $danger,
		hover-color: inherit,
		focus-color: inherit,
		disabled-color: inherit
	)
), $modal-danger);