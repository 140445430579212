////
/// @group alerts
////

$alert-padding-x: 1rem !default; // 16px
$alert-padding-y: 1.09375rem !default; // 17.5px

// For top only border use: 2px 0 0 0
$alert-border-width: 0.0625rem !default;

// @deprecated as of v2.12.0 use the Sass map `$alert-close` instead

$alert-close-opacity: 1 !default;

$alert-font-size: 0.875rem !default; // 14px

$alert-lead-font-size: $alert-font-size !default;
$alert-lead-font-weight: $font-weight-semi-bold !default;

$alert-link-decoration: underline !default;
$alert-link-hover-decoration: none !default;
$alert-link-font-weight: $font-weight-semi-bold !default;

$alert-indicator-font-size: inherit !default;

$alert-title: () !default;
$alert-title: map-deep-merge((
	font-size: 0.875rem,
	margin-bottom: 0.25rem,
	margin-top: 0
), $alert-title);

$alert-subtitle: () !default;
$alert-subtitle: map-deep-merge((
	font-size: 0.75rem,
	font-weight: $font-weight-normal
), $alert-subtitle);

// Alert Notification

$alert-notifications-box-shadow: 0 0.5rem 2rem -0.25rem rgba(0, 0, 0, 0.3) !default;

// Alert Variants

$alert-primary-color: $primary !default;
$alert-primary-bg: $primary-l3 !default;
$alert-primary-border-color: $primary-l1 !default;
$alert-primary-close-color: $alert-primary-color !default;
$alert-primary-close-hover-color: $alert-primary-color !default;
$alert-primary-lead-color: $alert-primary-color !default;
$alert-primary-link-color: $link-color !default;

$alert-secondary-color: $secondary !default;
$alert-secondary-bg: $secondary-l3 !default;
$alert-secondary-border-color: $secondary-l1 !default;
$alert-secondary-close-color: $alert-secondary-color !default;
$alert-secondary-close-hover-color: $alert-secondary-color !default;
$alert-secondary-lead-color: $alert-secondary-color !default;
$alert-secondary-link-color: $link-color !default;

$alert-success-color: $success !default;
$alert-success-bg: $success-l2 !default;
$alert-success-border-color: $success-l1 !default;
$alert-success-close-color: $alert-success-color !default;
$alert-success-close-hover-color: $alert-success-color !default;
$alert-success-lead-color: $alert-success-color !default;
$alert-success-link-color: $link-color !default;

$alert-info-color: $info !default;
$alert-info-bg: $info-l2 !default;
$alert-info-border-color: $info-l1 !default;
$alert-info-close-color: $alert-info-color !default;
$alert-info-close-hover-color: $alert-info-color !default;
$alert-info-lead-color: $alert-info-color !default;
$alert-info-link-color: $link-color !default;

$alert-warning-color: $warning !default;
$alert-warning-bg: $warning-l2 !default;
$alert-warning-border-color: $warning-l1 !default;
$alert-warning-close-color: $alert-warning-color !default;
$alert-warning-close-hover-color: $alert-warning-color !default;
$alert-warning-lead-color: $alert-warning-color !default;
$alert-warning-link-color: $link-color !default;

$alert-danger-color: $danger !default;
$alert-danger-bg: $danger-l2 !default;
$alert-danger-border-color: $danger-l1 !default;
$alert-danger-close-color: $alert-danger-color !default;
$alert-danger-close-hover-color: $alert-danger-color !default;
$alert-danger-lead-color: $alert-danger-color !default;
$alert-danger-link-color: $link-color !default;

$alert-light-color: $dark !default;
$alert-light-bg: $light-l2 !default;
$alert-light-border-color: $light-l1 !default;
$alert-light-close-color: $alert-light-color !default;
$alert-light-close-hover-color: $alert-light-color !default;
$alert-light-lead-color: $alert-light-color !default;
$alert-light-link-color: $link-color !default;

$alert-dark-color: $light !default;
$alert-dark-bg: $dark-l2 !default;
$alert-dark-border-color: $dark-l1 !default;
$alert-dark-close-color: $alert-dark-color !default;
$alert-dark-close-hover-color: $alert-dark-color !default;
$alert-dark-lead-color: $alert-dark-color !default;
$alert-dark-link-color: $alert-dark-color !default;