$dropdown-border-style: null !default;

$dropdown-full-wide-header-spacer-y: 20px !default;

$dropdown-wide-width: 500px !default;

$dropdown-font-size: null !default;
$dropdown-font-size-mobile: null !default;

$dropdown-header-font-size: null !default;
$dropdown-header-font-size-mobile: null !default;
$dropdown-header-margin-bottom: null !default;
$dropdown-header-margin-top: null !default;
$dropdown-header-padding-x: $dropdown-item-padding-x !default;
$dropdown-header-padding-y: $dropdown-padding-y !default;
$dropdown-header-text-transform: null !default;

$dropdown-subheader-color: $dropdown-header-color !default;
$dropdown-subheader-font-size: 0.75rem !default; // 12px
$dropdown-subheader-font-weight: $font-weight-semi-bold !default;
$dropdown-subheader-margin-bottom: null !default;
$dropdown-subheader-margin-top: null !default;
$dropdown-subheader-padding-x: $dropdown-header-padding-x !default;
$dropdown-subheader-padding-y: $dropdown-header-padding-y !default;
$dropdown-subheader-text-transform: uppercase !default;

$dropdown-caption-color: $dropdown-header-color !default;
$dropdown-caption-font-size: 0.875rem !default; // 14px
$dropdown-caption-font-weight: null !default;

$dropdown-link-font-weight: null !default;
$dropdown-link-active-font-weight: null !default;

$dropdown-max-height: 500px !default;
$dropdown-max-height-mobile: 295px !default;
$dropdown-min-height: null !default;

$dropdown-max-width: 260px !default;
$dropdown-max-width-mobile: 230px !default;

$dropdown-inline-scroller-max-height: 200px !default;
$dropdown-inline-scroller-max-height-mobile: none !default;

$dropdown-item-disabled-cursor: $disabled-cursor !default;
$dropdown-item-indicator-size: 1rem !default;
$dropdown-item-indicator-spacer-x: 1rem !default;

$dropdown-item-base: () !default;
$dropdown-item-base: map-deep-merge((
	border-radius: 0,
	overflow: hidden,
	padding-bottom: $dropdown-item-padding-y,
	padding-left: $dropdown-item-padding-x,
	padding-right: $dropdown-item-padding-x,
	padding-top: $dropdown-item-padding-y,
	position: relative,
	transition: none,
	white-space: normal,
	word-wrap: break-word,
	hover-text-decoration: none,
	active-color: $dropdown-link-active-color,
	active-class-font-weight: $dropdown-link-active-font-weight,
	disabled-color: $dropdown-link-disabled-color,
	disabled-cursor: $dropdown-item-disabled-cursor,
	disabled-opacity: 1,
), $dropdown-item-base);

// Autocomplete Dropdown Menu

$autocomplete-dropdown-menu: () !default;
$autocomplete-dropdown-menu: map-deep-merge((
	max-height: calc(9rem + 2px),
	max-width: none,
	width: 100%,
), $autocomplete-dropdown-menu);

// Dropdown Action

$dropdown-action-toggle-border-radius: $border-radius !default;
$dropdown-action-toggle-font-size: null !default;
$dropdown-action-toggle-size: $btn-monospaced-size-sm !default;

$dropdown-action-toggle-disabled-cursor: $disabled-cursor !default;
$dropdown-action-toggle-disabled-opacity: 0.65 !default;

// Alert inside Dropdowns

$dropdown-alert-font-size: null !default;
$dropdown-alert-line-height: normal !default;
$dropdown-alert-margin: ($spacer / 2) !default;
$dropdown-alert-padding-x: $dropdown-header-padding-x !default;
$dropdown-alert-padding-y: $dropdown-header-padding-y !default;