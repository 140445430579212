.timeline {
	@include list-unstyled;

	padding-left: $timeline-outer-spacing;

	.panel,
	.panel-group {
		margin-bottom: 0;
	}
}

.timeline-icon {
	background-color: $timeline-icon-bg;
	border: $timeline-icon-border-width solid $timeline-icon-border-color;
	border-radius: $timeline-icon-border-radius;
	display: block;

	@include clay-monospace($timeline-icon-size);
}

.timeline-increment {
	background-color: $timeline-increment-bg;
	left: -($timeline-inner-spacing - $timeline-border-modifier);
	position: absolute;
	text-align: center;
	top: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 1;
}

.timeline-increment-text {
	display: block;
	max-width: $timeline-inner-spacing + $timeline-outer-spacing;
}

.timeline-item-label {
	color: $timeline-item-label-color;
}

.timeline-item {
	padding-bottom: $timeline-item-padding-bottom;
	padding-left: $timeline-inner-spacing;
	padding-top: $timeline-item-padding-top;
	position: relative;

	&:before {
		background-color: $timeline-border-color;
		bottom: 0;
		content: '';
		display: block;
		left: 0;
		position: absolute;
		top: 0;
		width: $timeline-border-width;
	}

	&.active {
		.timeline-icon {
			background-color: $timeline-icon-active-bg;
			border-color: $timeline-icon-active-border-color;
		}
	}

	.panel,
	.panel-heading {
		position: relative;
	}

	.panel {
		.timeline-increment {
			margin-left: -$card-border-width;
		}
	}
}

// Timeline Right

.timeline-right {
	padding-left: 0;
	padding-right: $timeline-outer-spacing;

	.timeline-item {
		@include clay-timeline-item-reverse;
	}
}

.timeline-right-xs-only {
	@include media-breakpoint-down(xs) {
		padding-left: 0;
		padding-right: $timeline-outer-spacing;

		.timeline-item {
			@include clay-timeline-item-reverse;
		}
	}
}

// Timeline Center

.timeline-center {
	@include media-breakpoint-up(md) {
		padding-left: 0;

		.timeline-item {
			margin-left: 50%;
			width: 50%;

			.timeline-item-label {
				left: -100%;
				margin-left: -($timeline-inner-spacing * 2);
				position: absolute;
				text-align: right;
				top: 50%;
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
				width: 100%;
			}
		}

		.timeline-item.timeline-item-reverse {
			@include clay-timeline-item-reverse;

			margin-left: 0;

			.timeline-item-label {
				margin-left: auto;
				margin-right: -($timeline-inner-spacing * 2);
				right: -100%;
				text-align: left;
			}
		}
	}
}

.timeline-even .timeline-item:nth-of-type(even),
.timeline-odd .timeline-item:nth-of-type(odd) {
	@include media-breakpoint-up(md) {
		@include clay-timeline-item-reverse;

		margin-left: 0;

		.timeline-item-label {
			margin-left: auto;
			margin-right: -($timeline-inner-spacing * 2);
			right: -100%;
			text-align: left;
		}
	}
}

.timeline-spacing-xl {
	@include clay-timeline-spacing($timeline-outer-spacing-xl, $timeline-inner-spacing-xl, $timeline-item-padding-y-xl);

	.timeline-increment-text {
		max-width: $timeline-inner-spacing-xl + $timeline-outer-spacing-xl;
	}
}