$list-group-box-shadow: null !default;
$list-group-color: null !default;
$list-group-font-size: null !default;

$list-group-margin-bottom: 1.5rem !default;
$list-group-margin-top: null !default;

// Use values flex-start, flex-end, or center
$list-group-item-flex-align-items: flex-start !default;

$list-group-item-flex-checkbox-offset-top: 0.1875rem !default; // 3px
$list-group-item-flex-offset-top: 0.0625rem !default; // 1px
$list-group-item-flex-list-group-title-offset-top: -0.25rem !default; // -4px

// List Group Header

$list-group-header-bg: $list-group-bg !default;
$list-group-header-padding-x: $list-group-item-padding-x !default;
$list-group-header-padding-y: $list-group-item-padding-y !default;

$list-group-header-title: () !default;
$list-group-header-title: map-deep-merge((
	font-size: 1rem,
	font-weight: $font-weight-semi-bold,
	line-height: $headings-line-height,
	margin-bottom: 0
), $list-group-header-title);

// List Group Title

$list-group-title-link: () !default;
$list-group-title-link: map-deep-merge((
	color: $gray-900,
	max-width: 100%,
	hover-color: darken($gray-900, 15%)
), $list-group-title-link);

$list-group-title: () !default;
$list-group-title: map-deep-merge((
	font-size: 1.125rem, // 18px
	font-weight: $font-weight-semi-bold,
	line-height: $line-height-base,
	margin-bottom: 0,
	margin-top: $list-group-item-flex-list-group-title-offset-top,
	clay-link: $list-group-title-link
), $list-group-title);

$list-group-title-active-color: $list-group-active-color !default;

// List Group Subtitle

$list-group-subtitle-link: () !default;
$list-group-subtitle-link: map-deep-merge((
	color: $gray-600,
	hover-color: darken($gray-600, 15%)
), $list-group-subtitle-link);

$list-group-subtitle: () !default;
$list-group-subtitle: map-deep-merge((
	color: $gray-600,
	margin-bottom: 0,
	clay-link: $list-group-subtitle-link
), $list-group-subtitle);

// List Group Text

$list-group-text-link: () !default;
$list-group-text-link: map-deep-merge((
	color: $gray-900,
	hover-color: darken($gray-900, 15%)
), $list-group-text-link);

$list-group-text: () !default;
$list-group-text: map-deep-merge((
	color: $gray-900,
	margin-bottom: 0,
	clay-link: $list-group-text-link
), $list-group-text);

$list-group-text-active-color: $list-group-active-color !default;

// List Group Subtext

$list-group-subtext-link: () !default;
$list-group-subtext-link: map-deep-merge((
	color: $gray-600,
	hover-color: darken($gray-600, 15%)
), $list-group-subtext-link);

$list-group-subtext: () !default;
$list-group-subtext: map-deep-merge((
	color: $gray-600,
	margin-bottom: 0,
	clay-link: $list-group-subtext-link
), $list-group-subtext);

$list-group-subtext-active-color: $list-group-active-color !default;

// List Group Detail

$list-group-detail-margin-bottom: null !default;
$list-group-detail-margin-top: null !default;

$list-group-link-color: null !default;
$list-group-link-hover-color: null !default;
$list-group-link-active-color: $white !default;

// List Group Notification

$list-group-notification-item-border-bottom-color: $list-group-border-color !default;
$list-group-notification-item-border-left-color: $list-group-border-color !default;
$list-group-notification-item-border-right-color: $list-group-border-color !default;
$list-group-notification-item-border-top-color: $list-group-border-color !default;

$list-group-notification-item-active-border-bottom-color: $list-group-active-border-color !default;
$list-group-notification-item-active-border-left-color: $list-group-active-border-color !default;
$list-group-notification-item-active-border-right-color: $list-group-active-border-color !default;
$list-group-notification-item-active-border-top-color: $list-group-active-border-color !default;

$list-group-notification-item-border-bottom-width: 0.0625rem !default;
$list-group-notification-item-border-left-width: 0.0625rem !default;
$list-group-notification-item-border-right-width: 0.0625rem !default;
$list-group-notification-item-border-top-width: 0.0625rem !default;

$list-group-notification-item: () !default;

$list-group-notification-item-primary: () !default;
$list-group-notification-item-primary: map-deep-merge((
	border-left-color: theme-color-level(primary, -9),
	border-left-width: 0.5rem,
	border-bottom-left-radius: 0,
	border-top-left-radius: 0,
	active-border-left-color: theme-color-level(primary, -9)
), $list-group-notification-item-primary);

// List Group Sm

$list-group-sm-item: () !default;
$list-group-sm-item: map-deep-merge((
	padding-bottom: 0.40625rem,
	padding-top: 0.40625rem,
), $list-group-sm-item);

$list-group-sm-quick-action-menu: () !default;
$list-group-sm-quick-action-menu: map-deep-merge((
	margin-bottom: -0.40625rem,
	margin-top: -0.40625rem,
	padding-bottom: 0.40625rem,
	padding-top: 0.40625rem,
), $list-group-sm-quick-action-menu);