////
/// @group sheet
////

$sheet-bg: $body-bg !default;
$sheet-border-color: $gray-300 !default;
$sheet-border-style: solid !default;
$sheet-border-width: 0.0625rem !default; // 1px

$sheet-border-radius: $border-radius !default;

$sheet-padding-bottom: 0.0625rem !default; // 1px
$sheet-padding-left: 1.5rem !default; // 24px
$sheet-padding-right: 1.5rem !default; // 24px
$sheet-padding-top: 1.5rem !default; // 24px

$sheet-padding-bottom-mobile: null !default;
$sheet-padding-left-mobile: null !default;
$sheet-padding-right-mobile: null !default;
$sheet-padding-top-mobile: null !default;

// Sheet Header

$sheet-header-margin-bottom: 3rem !default; // 48px
$sheet-header-margin-bottom-mobile: null !default;

// Sheet Section

$sheet-section-margin-bottom: 3rem !default; // 48px
$sheet-section-margin-bottom-mobile: null !default;

$sheet-panel-group-margin-bottom: 1.5rem !default; // 24px
$sheet-panel-group-margin-bottom-mobile: null !default;

// Sheet Footer

$sheet-footer-margin-bottom: 1.5rem !default; // 24px
$sheet-footer-margin-left: null !default;
$sheet-footer-margin-right: null !default;
$sheet-footer-margin-top: 1.5rem !default; // 24px

$sheet-footer-margin-bottom-mobile: null !default;
$sheet-footer-margin-left-mobile: null !default;
$sheet-footer-margin-right-mobile: null !default;
$sheet-footer-margin-top-mobile: null !default;

$sheet-footer-btn-block-sm-down: () !default;
$sheet-footer-btn-block-sm-down: map-deep-merge((
	breakpoint-down: sm,
	btn-margin-bottom-mobile: 1rem
), $sheet-footer-btn-block-sm-down);

// Sheet Sizes

/// @deprecated as of 3.x.

$sheet-lg-max-width: ceil(map-get($container-max-widths, lg) * 0.83333) !default; // 800px

// Sheet Title

$sheet-title-color: null !default;
$sheet-title-font-size: 1.5rem !default; // 24px
$sheet-title-font-weight: $font-weight-semi-bold !default;
$sheet-title-line-height: $headings-line-height !default; // 1.2
$sheet-title-margin-bottom: 1.5rem !default; // 24px

$sheet-title-font-size-mobile: 1.25rem !default; // 20px
$sheet-title-margin-bottom-mobile: null !default;

// Sheet Subtitle

$sheet-subtitle-border-color: null !default;
$sheet-subtitle-border-style: solid !default;
$sheet-subtitle-border-width: 0 0 1px 0 !default;
$sheet-subtitle-color: null !default;
$sheet-subtitle-font-size: 0.875rem !default; // 14px
$sheet-subtitle-font-weight: $font-weight-semi-bold !default;
$sheet-subtitle-line-height: $headings-line-height !default; // 1.2
$sheet-subtitle-margin-bottom: 1.5rem !default; // 24px
$sheet-subtitle-padding-x: null !default;
$sheet-subtitle-padding-y: 0.3125rem !default; // 5px
$sheet-subtitle-text-transform: uppercase !default;

$sheet-subtitle-font-size-mobile: null !default;
$sheet-subtitle-margin-bottom-mobile: null !default;

$sheet-subtitle-heading-text-margin-bottom: 0 !default;
$sheet-subtitle-heading-text-margin-top: null !default;

// Sheet Subtitle as Panel Header

$sheet-subtitle-link-color: $sheet-subtitle-color !default;
$sheet-subtitle-link-text-decoration: null !default;
$sheet-subtitle-link-hover-color: null !default;
$sheet-subtitle-link-hover-text-decoration: none !default;

$sheet-subtitle-link: () !default;
$sheet-subtitle-link: map-deep-merge((
	color: $sheet-subtitle-link-color,
	text-decoration: $sheet-subtitle-link-text-decoration,
	hover-color: $sheet-subtitle-link-hover-color,
	hover-text-decoration: $sheet-subtitle-link-hover-text-decoration,
), $sheet-subtitle-link);

$sheet-subtitle-collapse-icon-font-size: null !default;
$sheet-subtitle-collapse-icon-bottom: null !default;
$sheet-subtitle-collapse-icon-left: null !default;
$sheet-subtitle-collapse-icon-right: null !default;
$sheet-subtitle-collapse-icon-top: clay-collapse-icon-align($sheet-subtitle-padding-y, 0, 1em, $sheet-subtitle-line-height) !default;

$sheet-subtitle-collapse-icon-padding-left: null !default;
$sheet-subtitle-collapse-icon-padding-right: null !default;

// Sheet Tertiary Title

$sheet-tertiary-title-color: $sheet-subtitle-color !default;
$sheet-tertiary-title-font-size: $sheet-subtitle-font-size !default;
$sheet-tertiary-title-font-weight: $sheet-subtitle-font-weight !default;
$sheet-tertiary-title-line-height: $sheet-subtitle-line-height !default;
$sheet-tertiary-title-margin-bottom: $sheet-subtitle-margin-bottom !default;
$sheet-tertiary-title-padding-x: $sheet-subtitle-padding-x !default;
$sheet-tertiary-title-padding-y: $sheet-subtitle-padding-y !default;
$sheet-tertiary-title-text-transform: $sheet-subtitle-text-transform !default;

$sheet-tertiary-title-font-size-mobile: null !default;
$sheet-tertiary-title-margin-bottom-mobile: null !default;

// Sheet Text

$sheet-text-color: null !default;
$sheet-text-font-size: null !default;
$sheet-text-font-weight: null !default;
$sheet-text-margin-bottom: 1.5rem !default; // 24px

$sheet-text-font-size-mobile: null !default;
$sheet-text-margin-bottom-mobile: null !default;