$loading-icon-font-size: 2.5rem !default; // 40px

$loading-icon-font-size-sm: 1.25rem !default; // 20px

// Loading Icon Dotted

$loading-animation: () !default;
$loading-animation: map-deep-merge((
	animation-name: loading-animation,
	color: $gray-900,
), $loading-animation);

$loading-animation-light: () !default;
$loading-animation-light: map-deep-merge((
	animation-name: loading-animation-light,
	color: $white
), $loading-animation-light);