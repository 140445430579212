$panel-bg: $white !default;
$panel-border-color: transparent !default;
$panel-border-style: solid !default;

$panel-border-bottom-width: 1px !default;
$panel-border-left-width: 1px !default;
$panel-border-right-width: 1px !default;
$panel-border-top-width: 1px !default;
$panel-border-width: $panel-border-top-width $panel-border-right-width $panel-border-bottom-width $panel-border-left-width !default;

$panel-border-radius: $border-radius !default;
$panel-box-shadow: null !default;
$panel-margin-bottom: $spacer !default;

// Panel Header

$panel-header-border-bottom-width: 1px !default;
$panel-header-offset-border-radius: if($panel-border-top-width > 0, calc(#{$panel-border-radius} - #{$panel-border-top-width}), $panel-border-radius) !default;
$panel-header-font-size: $font-size-base !default;
$panel-header-line-height: $headings-line-height !default;
$panel-header-padding-x: 1.25rem !default;
$panel-header-padding-y: 0.75rem !default;

$panel-header-collapse-icon-font-size: 0.875rem !default; // 14px
$panel-header-collapse-icon-bottom: null !default;
$panel-header-collapse-icon-left: null !default;
$panel-header-collapse-icon-right: null !default;
$panel-header-collapse-icon-top: clay-collapse-icon-align($panel-header-padding-y, 0, 1em, $panel-header-line-height) !default;

$panel-header-collapse-icon-padding-left: null !default;
$panel-header-collapse-icon-padding-right: null !default;

$panel-header-link-text-decoration: null !default;
$panel-header-link-hover-text-decoration: null !default;

$panel-header-link: () !default;
$panel-header-link: map-deep-merge((
	color: inherit,
	display: block,
	text-decoration: $panel-header-link-text-decoration,
	transition: border-color 0.1s ease#{','} border-radius 0.5s ease,
	hover-color: inherit,
	hover-text-decoration: $panel-header-link-hover-text-decoration,
	focus-z-index: $zindex-panel-header-link-focus,
), $panel-header-link);

$panel-header-collapsed-link: () !default;
$panel-header-collapsed-link: map-deep-merge((
	border-color: transparent,
	transition: border-color 0.75s ease,
), $panel-header-collapsed-link);

// Panel Body

$panel-body-padding-x: 1.25rem !default;
$panel-body-padding-y: 0.75rem !default;

// Panel Footer

$panel-footer-border-top-width: 1px !default;
$panel-footer-offset-border-radius: if($panel-border-bottom-width > 0, calc(#{$panel-border-radius} - #{$panel-border-bottom-width}), $panel-border-radius) !default;
$panel-footer-padding-x: $panel-header-padding-x !default;
$panel-footer-padding-y: $panel-header-padding-y !default;

// Panel Title

$panel-title-font-size: null !default;
$panel-title-font-weight: $font-weight-semi-bold !default;
$panel-title-text-transform: null !default;

// Panel Title Small Element

$panel-title-small-font-size: null !default;
$panel-title-small-margin-left: null !default;

// Panel Group

$panel-group-panel-margin-bottom: -$panel-border-bottom-width !default;

// Panel Group Sm

$panel-group-sm: () !default;
$panel-group-sm: map-deep-merge((
	font-size: 0.875rem,
	header-padding-bottom: 0.5rem,
	header-padding-top: 0.5rem,
	title-font-size: 0.875rem,
	collapse-icon-top: 0.6875rem,
), $panel-group-sm);

// Panel Group Flush

$panel-group-flush-body-margin-bottom: 1.5rem !default;
$panel-group-flush-body-padding: 0 !default;

$panel-group-flush-collapse-icon-left: null !default;
$panel-group-flush-collapse-icon-right: 0 !default;
$panel-group-flush-collapse-icon-padding-left: null !default;
$panel-group-flush-collapse-icon-padding-right: 1.5rem !default;

// Panel Variants

$panel-secondary-header-link: () !default;

$panel-secondary: () !default;
$panel-secondary: map-deep-merge((
	border-color: rgba($black, 0.125),
	header-bg: $gray-100,
	header-border-color: rgba($black, 0.125),
	header-link: $panel-secondary-header-link,
	footer-bg: $gray-100,
	footer-border-color: rgba($black, 0.125)
), $panel-secondary);

// Panel Unstyled

$panel-unstyled-header-link: () !default;

$panel-unstyled: () !default;
$panel-unstyled: map-deep-merge((
	bg: transparent,
	border-radius: 0,
	border-width: 0,
	header-border-color: $gray-500,
	header-border-style: solid,
	header-border-width: 0 0 1px 0,
	header-margin-bottom: 1rem,
	header-collapsed-border-color: $gray-500,
	header-link: $panel-unstyled-header-link,
	body-margin-bottom: 1rem,
	body-padding-top: 0,
), $panel-unstyled);