.sticker {
	align-items: center;
	border-color: $sticker-border-color;

	@include border-radius($sticker-border-radius);

	border-style: $sticker-border-style;
	border-width: $sticker-border-width;
	color: $sticker-color;

	@include clay-monospace($sticker-size);

	display: inline-flex;
	font-size: $sticker-font-size;
	font-weight: $sticker-font-weight;
	justify-content: center;
	position: relative;
	text-align: center;
	vertical-align: middle;

	> .inline-item {
		font-size: $sticker-inline-item-font-size;
		justify-content: center;
	}

	> .inline-item .lexicon-icon,
	> .sticker-overlay .lexicon-icon,
	> .lexicon-icon {
		margin-top: 0;
	}

	&.rounded .sticker-overlay {
		border-radius: $rounded-border-radius;
	}

	&.rounded-circle .sticker-overlay {
		border-radius: $rounded-circle-border-radius;
	}

	&.rounded-0 .sticker-overlay {
		border-radius: 0;
	}
}

.sticker-img {
	@extend %aspect-ratio-item-center-middle !optional;
	@extend %aspect-ratio-item-fluid !optional;
}

.sticker-overlay {
	align-items: center;

	@include border-radius($sticker-border-radius);

	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
}

// Sticker Positions

.sticker-bottom-left {
	bottom: $sticker-inside-offset;
	left: $sticker-inside-offset;
	position: absolute;
	right: auto;
	top: auto;
}

.sticker-bottom-right {
	bottom: $sticker-inside-offset;
	left: auto;
	position: absolute;
	right: $sticker-inside-offset;
	top: auto;
}

.sticker-top-left {
	left: $sticker-inside-offset;
	position: absolute;
	top: $sticker-inside-offset;
}

.sticker-top-right {
	left: auto;
	position: absolute;
	right: $sticker-inside-offset;
	top: $sticker-inside-offset;
}

.sticker-outside {
	left: $sticker-outside-offset;
	top: $sticker-outside-offset;

	&.sticker-bottom-left {
		bottom: $sticker-outside-offset;
		top: auto;
	}

	&.sticker-bottom-right {
		bottom: $sticker-outside-offset;
		left: auto;
		right: $sticker-outside-offset;
		top: auto;
	}

	&.sticker-top-right {
		left: auto;
		right: $sticker-outside-offset;
	}
}

// Sticker User Icon

.sticker-user-icon {
	@include clay-sticker-variant($sticker-user-icon);
}

// Sticker Sizes

.sticker-sm {
	@include clay-sticker-size($sticker-sm);
}

.sticker-lg {
	@include clay-sticker-size($sticker-lg);
}

.sticker-xl {
	@include clay-sticker-size($sticker-xl);
}

// Sticker Variants

@each $color, $value in $sticker-palette {
	.sticker-#{$color} {
		background-color: map-get($value, bg);
		border-color: map-get($value, border-color);
		color: map-get($value, color);
	}
}

// Sticker Circle

.sticker-circle,
.sticker-circle .sticker-overlay {
	@include border-radius($sticker-circle-border-radius);
}