.breadcrumb-link {
	@include clay-link($breadcrumb-link);

	> .breadcrumb-text-truncate {
		text-decoration: $breadcrumb-link-text-decoration;

		&:hover,
		&:focus {
			text-decoration: $breadcrumb-link-hover-text-decoration;
		}
	}
}

.breadcrumb-item {
	font-size: $breadcrumb-font-size;
	font-weight: $breadcrumb-font-weight;

	@if ($breadcrumb-divider-svg-icon) {
		margin-right: 0.5em;
		position: relative;
	}

	&.active {
		font-weight: $breadcrumb-active-font-weight;
	}

	> span {
		text-transform: $breadcrumb-text-transform;
	}

	+ .breadcrumb-item {
		@if ($breadcrumb-divider-svg-icon) {
			padding-left: 1em;
		}

		&::before {
			float: left;

			@if ($breadcrumb-divider-svg-icon) {
				background-image: $breadcrumb-divider-svg-icon;
				background-repeat: no-repeat;
				background-size: 100%;
				content: '';
				display: block;
				height: $breadcrumb-divider-svg-icon-height;
				left: 0;
				margin-top: -$breadcrumb-divider-svg-icon-height / 2;
				padding: 0;
				position: absolute;
				top: 50%;
				width: $breadcrumb-divider-svg-icon-width;
			}
			@else {
				font-family: $breadcrumb-divider-font-family;
				font-weight: $breadcrumb-divider-font-weight;
				padding-left: 0.4em;
				padding-right: 0.4em;
			}
		}
	}

	.dropdown-toggle {
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}

.breadcrumb-text-truncate {
	display: inline-block;
	max-width: $breadcrumb-text-truncate-max-width;

	@include text-truncate;

	vertical-align: bottom;

	@include clay-scale-component {
		max-width: $breadcrumb-text-truncate-max-width-mobile;
	}
}