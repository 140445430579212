@mixin clay-autofit-float {
	flex-wrap: wrap;

	> .autofit-col {
		max-width: 100%;
	}

	// `.autofit-col end` is deprecated in v2.0.0 use `.autofit-float-end`
	// pattern instead

	> .autofit-col-end {
		margin-left: auto;

		+ .autofit-col-end {
			margin-left: 0;
		}
	}
}